import axios from 'axios';

const TIMEOUT = 50000;

/**
 * Creates an axios instance for a specific API endpoint
 * @param {string} endpoint - The endpoint name (auth, company-backend, lead-form, etc.)
 * @param {Object} options - Additional configuration options
 * @returns {import('axios').AxiosInstance}
 */
export const createApiClient = (endpoint, options = {}) => {
  // Determine base URL based on environment
  let baseUrl;
  try {
    // Cypress environment
    if (endpoint === 'location') {
      baseUrl = Cypress.env('LOCATION_API_URL') + '/';
    } else {
      baseUrl = Cypress.env('BACKEND_API_URL') + '/' + endpoint;
    }
  } catch (e) {
    // Regular environment
    const isTestingEnv = window.location.hostname.split('.')[0] === 'testing';

    if (endpoint === 'location') {
      baseUrl = isTestingEnv
        ? import.meta.env.VITE_VUE_TESTING_LOCATION_API_URL + '/'
        : import.meta.env.VITE_VUE_APP_LOCATION_API_URL + '/';
    } else {
      const baseApiUrl = isTestingEnv
        ? import.meta.env.VITE_VUE_TESTING_BACKEND_API_URL
        : import.meta.env.VITE_VUE_APP_BACKEND_API_URL;
      baseUrl = `${baseApiUrl}/${endpoint}`;
    }
  }

  // Create axios instance with default config
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      accept: 'application/json',
      ...options.headers
    },
    withCredentials: true, // required to handle the CSRF token
    withXSRFToken: true,
    ...options
  });

  // We're not adding interceptors here - those will be handled by middleware

  return axiosInstance;
};

// Cache for API clients
const apiClients = {};

/**
 * Get or create an API client for a specific endpoint
 * @param {string} endpoint - The endpoint name (auth, company-backend, lead-form, location)
 * @param {Object} options - Additional configuration options
 * @returns {import('axios').AxiosInstance}
 */
export const getApiClient = (endpoint, options = {}) => {
  // Map the endpoint to the actual backend path
  const endpointMap = {
    'auth': 'auth',
    'company': 'company-backend',
    'leadForm': 'lead-form',
    'location': 'location'
  };

  const mappedEndpoint = endpointMap[endpoint] || endpoint;

  // Create the client if it doesn't exist
  if (!apiClients[mappedEndpoint]) {
    apiClients[mappedEndpoint] = createApiClient(mappedEndpoint, options);
  }

  return apiClients[mappedEndpoint];
};

/**
 * Convenience methods for commonly used endpoints
 */
export const authApi = getApiClient('auth');
export const companyApi = getApiClient('company');
export const leadFormApi = getApiClient('leadForm');
export const locationApi = getApiClient('location');

export default {
  getApiClient,
  authApi,
  companyApi,
  leadFormApi,
  locationApi
};
