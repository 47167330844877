<template>
  <div
    v-if="loading"
    class="triangle loading-indicator"
  />
  <div
    v-else-if="required || labelState"
    class="triangle"
    :class="labelState ? 'label-success' : 'label-warning'"
  />
</template>

<script>
  export default {
    name: 'LabelStateIcon',
    props: {
      labelState: Boolean,
      required: Boolean,
      selectField: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
  };
</script>

<style scoped>
    .triangle {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 35px;
        right: 6px;
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
    }
    .label-warning {
        border-color: #87b1ff;
        background-color: #87b1ff;
    }
    .label-success {
        border-color: #95C11F;
        background-color: #95C11F;
    }
    .loading-indicator {
        width: 10px;
        height: 10px;
        border-color: transparent;
        border-top-color: #1976D2;
        border-left-color: #1976D2;
        border-width: 2px;
        border-radius: 50%;
        background-color: transparent;
        animation: spin 0.8s linear infinite;
        box-shadow: 0 0 0 1px rgba(25, 118, 210, 0.3);
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
