<template>
  <layout>
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold text-center mt-6">{{ $t('publicArea.registerCompany.registerCompany.becomePartner') }}</p>
      <p class="text-center text-xs sm:text-sm mt-2">{{ $t('publicArea.registerCompany.registerCompany.registerCompany') }}</p>
    </template>

    <template v-slot:filters>
      <div class="flex justify-center mt-4">
        <info-circle :color="step >= 1 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(1)">1</button></info-circle>
        <info-circle :color="step >= 2 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(2)">2</button></info-circle>
        <info-circle :color="step >= 3 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(3)">3</button></info-circle>
        <info-circle :color="step >= 4 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(4)">4</button></info-circle>
        <info-circle :color="step >= 5 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(5)">5</button></info-circle>
        <info-circle v-if="step > 1 && !websiteUrl && showWebsiteSubscription" :color="step >= 6 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(6)">6</button></info-circle>
      </div>
    </template>

    <div class="sm:panel min-h-[400px]"> <!-- Adjust min-h value as needed -->
      <div class="h-full flex items-center justify-center">
        <spinner v-if="isLoading" />
          <component v-else
            :is="currentComponent"
            :company="company"
            :uid-data="uidData"
            @next-step="goToNextStep"
          />
      </div>
    </div>
  </layout>
</template>

<script>
  import { shallowRef } from 'vue';
  import Area from './steps/Area.vue';
  import TermsOfService from './steps/TermsOfService.vue';
  import RegisterUserWithCompany from './steps/RegisterUserWithCompany.vue';
  import Uid from './steps/Uid.vue';
  import Layout from '../../Layout.vue';
  import Classification from './steps/Classification.vue';
  import InfoCircle from '../../snippets/InfoCircle.vue';
  import store from '../../store/mainStore';
  import WebsiteSubscription from './steps/WebsiteSubscription.vue';
  import { companyApiVueQuery } from '@/api/api.js';
  import Spinner from '@snippets/Spinner.vue';

  const UidComponent = shallowRef(Uid);
  const RegisterLayoutWithCompanyComponent = shallowRef(RegisterUserWithCompany);
  const ClassificationComponent = shallowRef(Classification);
  const AreaComponent = shallowRef(Area);
  const TosComponent = shallowRef(TermsOfService);
  const WebsiteSubscriptionComponent = shallowRef(WebsiteSubscription);

  export default {
    name: 'RegisterCompany',
    components: {
      Spinner,
      InfoCircle,
      Layout,
      RegisterLayoutWithCompanyComponent,
      ClassificationComponent,
      AreaComponent,
    },
    data() {
      return {
        step: 1,
        uidData: null,
        menu: this.$router.options.routes.filter(obj => obj.meta.layout === 'public'),
        currentComponent: UidComponent,
        isLoading: true,
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      companyId() {
        return store.state.main.companies[0]?.company_id;
      },
      websiteUrl() {
        return this.company && this.company.websites ? this.company.websites[0].url : undefined;
      },
      user() {
        return store.state.main.user;
      },
      isAuthenticated() {
        return !!store.state.main.user;
      },
      showWebsiteSubscription() {
        return import.meta.env.VITE_VUE_APP_ENV !== 'production';
      },
    },
    mounted() {
      if ((import.meta.env.VITE_VUE_APP_ENV !== 'production' || this.$route.query.test) && this.$route.query.step) {
        this.step = parseInt(this.$route.query.step);
        this.goToStep();
        this.isLoading = false;
      } else {
        this.getCurrentStep();
      }
    },
    methods: {
      // Get the current registration step from the API
      getCurrentStep() {
        this.isLoading = true;
        companyApiVueQuery.get( '/get-register-step' )
          .then(response => {
            if (response.data && response.data.content.step) {
              this.step = response.data.content.step;
              if (response.data.content.user) {
                store.commit('setUser', response.data.content.user, { root: true });
                store.commit('company/setInitialCompanyData', response.data.content.company);
                store.commit('setCompanies', response.data.content.employees);
                store.commit('setSelectedCompany', response.data.content.company.id);
              }
              this.goToStep();
            }
          })
          .catch(error => {
            console.error('Error fetching registration step:', error);
            this.step = 1;
            this.goToStep();
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      goToNextStep(data) {
        // Store the emitted data if provided
        if (data) {
          this.uidData = data;
        }

        // Check if we should navigate to the final destination
        const maxStep = this.showWebsiteSubscription ? 6 : 5;
        if (this.step >= maxStep.value || (this.step === 5 && this.websiteUrl)) {
          console.log('Registration complete - navigating to PendingLeads page');

          if (this.companyId) {
            // Use direct navigation to PendingLeads without calling goToStep
            this.$router.push({
              name: 'PendingLeads',
              params: { company_id: this.companyId }
            }).then(() => {
              console.log('Navigation to PendingLeads successful');
            }).catch(err => {
              console.error('Navigation to PendingLeads failed:', err);
              // Fallback to direct URL navigation
              window.location.href = `/pending-leads/${this.companyId}`;
            });

            // Important: Return early to prevent further processing
            return;
          } else {
            console.error('Cannot navigate to PendingLeads: companyId is undefined');
          }
        }

        // For normal steps, increment and continue
        this.step++;
        this.goToStep();
      },
      tryGoToStep(step) {
        if (this.step === 1 && step === 2 && this.uidData) {
          this.step = step;
          this.goToStep();
        }
        if (this.step === 2 && step === 1 ) {
          this.step = step;
          this.goToStep();
        }
      },
      goToStep: function () {
        // If we're beyond the registration steps, navigate to PendingLeads
        if (this.step > 6) {
          console.log('Step > 6, navigating to PendingLeads');

          if (this.companyId) {
            this.$router.push({
              name: 'PendingLeads',
              params: { company_id: this.companyId }
            }).then(() => {
              console.log('Navigation to PendingLeads successful');
            }).catch(err => {
              console.error('Navigation to PendingLeads failed:', err);
              // Fallback to direct URL navigation
              window.location.href = `/pending-leads/${this.companyId}`;
            });

            // Important: Return early to prevent setStepQuery from being called
            return;
          } else {
            console.error('Cannot navigate to PendingLeads: companyId is undefined');
            return; // Still return to prevent setStepQuery
          }
        }

        // Handle normal steps
        switch (this.step) {
          case 1:
            this.currentComponent = UidComponent;
            break;
          case 2:
            this.currentComponent = RegisterLayoutWithCompanyComponent;
            break;
          case 3:
            this.currentComponent = ClassificationComponent;
            break;
          case 4:
            this.currentComponent = AreaComponent;
            break;
          case 5:
            this.currentComponent = TosComponent;
            break;
          case 6:
            this.currentComponent = WebsiteSubscriptionComponent;
            break;
        }

        // Only update query params for regular steps
        this.setStepQuery();
      },
      setStepQuery: function () {
        const currentQueryParams = { ...this.$route.query };
        currentQueryParams.step = this.step;
        this.$router.push({
          path: '/register-company',
          query: currentQueryParams
        }).catch(err => {
          // Silently ignore navigation duplicates
          if (err.name !== 'NavigationDuplicated') {
            console.error('Error updating step query:', err);
          }
        });
      },
    },
  };
</script>

<style scoped>
</style>
