<template>
  <div class="relative mt-2 rounded shadow-md p-4 bg-white w-full" >
    <div class="flex justify-between cursor-pointer" @click="toggleShow">
      <div class="font-bold text-base">{{ $t('customerArea.quoteOverview.explanation.title') }}</div>
      <div><circle-button :icon="['fas', isExpanded ? 'caret-up' : 'caret-down']" size="xs" /></div>
    </div>
    <div v-if="localShow">
      <div v-if="lead.desired_quotes > lead.taken_by" class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.companySearchTitle') }}</div>
      <div v-if="lead.desired_quotes > lead.taken_by">{{ $t('customerArea.quoteOverview.explanation.companySearchText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.companyContactTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.companyContactText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.selectCompanyTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.selectCompanyText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.reviewTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.reviewText') }}</div>
    </div>

  </div>
</template>

<script>
  import CircleButton from '../../snippets/CircleButton.vue';

  export default {
    name: 'Explanation',
    components: { CircleButton },
    props: {
      lead: Object,
      isExpanded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localShow: false
      }
    },
    watch: {
      // Synchronisiere den lokalen Zustand mit dem Prop
      isExpanded(newVal) {
        this.localShow = newVal;
      }
    },
    methods: {
      toggleShow() {
        this.localShow = !this.localShow;
        // Event nach oben senden, um dem Parent mitzuteilen, dass sich der Zustand geändert hat
        this.$emit('toggle');
      }
    }
  };
</script>