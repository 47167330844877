<template>
  <base-form-input
    :id="id"
    v-model="phoneNumber"
    :label="defaultLabel"
    :errors="validationErrors"
    :is-valid="isValid"
    :help-text="helpText"
    :container-class="containerClass"
    :input-class="inputClass"
    :placeholder="placeholder"
    :required="required"
    :show-icon="showIcon"
    :loading="isPending"
    @blur="validatePhone"
  />
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import { useI18n } from 'vue-i18n';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import { companyApiVueQuery, createMutationFn } from '@/api/api.js';

  const { t } = useI18n({ useScope: 'global' });

  // Model for two-way binding
  const phoneModel = defineModel();

  // Props
  const props = defineProps({
    id: {
      type: String,
      default: 'phone',
    },
    label: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    initialErrors: {
      type: Array,
      default: () => null,
    },
  });

  // Emits
  const emit = defineEmits(['validation-success', 'validation-error', 'phone-changed']);

  // Reactive state
  const phoneNumber = ref(phoneModel.value || '');
  const validationErrors = ref(props.initialErrors);
  const phoneLastValue = ref(phoneModel.value || '');
  const isValidating = ref(false);

  // Computed properties
  const isValid = computed(() => Boolean(phoneNumber.value) && !validationErrors.value);
  const defaultLabel = computed(() => props.label || t('general.Phone'));

  // Watch for changes to synchronize the model
  watch(phoneNumber, (newValue) => {
    phoneModel.value = newValue;
    emit('phone-changed', newValue);
  });

  // Watch for external model changes
  watch(() => phoneModel.value, (newValue) => {
    if (newValue !== phoneNumber.value) {
      phoneNumber.value = newValue;
    }
  });

  // Watch for external error changes
  watch(() => props.initialErrors, (newErrors) => {
    validationErrors.value = newErrors;
  });

  // Phone validation mutation using the API client directly
  const { isPending, isError, error, isSuccess, mutate } = useMutation({
    mutationFn: (phone) => companyApiVueQuery.post('register/phone/check', { phone }),
    onSuccess: (response) => {
      const data = response.data;
      if (data.notificationType === 'success') {
        validationErrors.value = null;
        phoneNumber.value = data.content.phone;
        emit('validation-success', data.content.phone);
      } else {
        validationErrors.value = [t('publicArea.registerCompany.steps.registerUserWithCompany.warningPhone')];
        emit('validation-error', validationErrors.value);
      }
      isValidating.value = false;
    },
    onError: () => {
      validationErrors.value = [t('publicArea.registerCompany.steps.registerUserWithCompany.warningPhone')];
      emit('validation-error', validationErrors.value);
      isValidating.value = false;
    }
  });

  // Validation function
  function validatePhone() {
    if (phoneNumber.value && phoneLastValue.value !== phoneNumber.value) {
      validationErrors.value = null;
      isValidating.value = true;
      mutate(phoneNumber.value);
    }
    phoneLastValue.value = phoneNumber.value;
  }
</script>
