import { useToast, POSITION } from 'vue-toastification'
import { i18n } from '@/main.js';

const notification = {
  error: (message = i18n.global.t('general.serverError')) => {
    return useToast().error(message, {
      position: POSITION.BOTTOM_CENTER,
    });
  },
  success: (message = i18n.global.t('general.completed')) => {
    return useToast().success(message,{
      position: POSITION.TOP_RIGHT,
      toastRole: "success"
    });
  },
  progress: (message = i18n.global.t('general.inProgress')) => {
    return useToast().warning(message, {
      position: POSITION.TOP_CENTER,
      toastRole: "progress"
    });
  },
  warning: (message, title = i18n.global.t('general.attention')) => {
    return useToast().warning(message, {
      position: POSITION.TOP_CENTER,
      toastRole: "warning"
    });
  },
  destroy: () => {
    return useToast().clear();
  },
};

export default notification;
