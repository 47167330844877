export const useNoAutocomplete = () => ({

  /**
   * Attributes to prevent autocomplete and password manager suggestions
   * Covers the following password managers and browsers:
   * - General browsers
   * - 1Password
   * - LastPass
   * - Dashlane
   * - Keeper
   * - RoboForm
   * - NordPass
   * - Firefox
   * - Chrome
   * - Safari
   */

  // Browser native attributes
  autocomplete: 'off',
  autocapitalize: 'off',
  spellcheck: 'false',

  // Generic prevention
  'data-browser-autocomplete': 'off',
  'data-autofill': 'false',
  'data-form-type': 'other',

  // Password manager specific prevention
  'data-1p-ignore': '',        // 1Password
  'data-lpignore': 'true',     // LastPass
  'data-bwignore': '',         // Bitwarden
  'data-pw-ignore': '',        // Generic password managers
  'data-dashlane-ignore': '',  // Dashlane
  'data-keeper-ignore': '',    // Keeper
  'data-roboform-ignore': '',  // RoboForm
  'data-nordpass-ignore': '',  // NordPass

  // Browser specific prevention
  'data-firefox-ignore': '',   // Firefox
  'data-chrome-ignore': '',    // Chrome
  'data-safari-ignore': '',     // Safari
})
