<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ title }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">{{ infoText }}</p>
    </template>

    <template v-slot:filters>
      <div v-if="!isPreferred" class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <button class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1" @click="storePreferences('month')">{{ closeMonthText }}</button>
        <button class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1" @click="deletePreferences('month')">{{ openMonthText }}</button>
      </div>
    </template>

    <div class="panel">
      <p class="mt-2 mb-5">{{ infoText2 }}</p>
      <p class="mt-2 mb-5" v-html="infoText3"></p>
      <vue-cal ref="vuecal"
               class="vuecal--rounded-theme"
               :class="{ 'vuecal--closed-days': !isPreferred, 'vuecal--preferred-days': isPreferred }"
               hide-view-selector
               :time="false"
               :events="availabilityPreferences"
               active-view="month"
               :disable-views="['day', 'years', 'year', 'week']"
               :locale="locale"
               style="height: 500px"
               @cell-click="singleClick($event)"
               @cell-dblclick="doubleClick($event)"
               @ready="month = $event.startDate"
               @view-change="month = $event.startDate"
      />
      <p class="text-lg font-semibold mt-5">{{ $t('companyArea.settings.closedDays.legend') }}</p>
      <p class="mt-1">
        <strong>{{ $t('general.click') }}</strong>: {{ legendClickText }}<br>
        <span v-if="!isPreferred"><strong>{{ $t('general.doubleClick') }}</strong>: {{ legendDoubleClickText }}</span>
      </p>
      <div class="flex mt-2">
        <div class="block h-5 w-5 mr-3">
          <div class="rounded-full h-5 w-5" :class="{ 'bg-red-600': !isPreferred, 'bg-green-600': isPreferred }" />
        </div>
        {{ dayStatusText }}<br>{{ dayStatusInfoText }}
      </div>
    </div>

    <confirmation-modal v-if="show.newEvent" @close="show.newEvent = false">
      <div class="flex justify-center">
        <button class="btn btn-md btn-primary mr-2" @click="storePreferences('week'); show.newEvent = false">{{ closeWeekText }}</button>
      </div>

      <div class="flex justify-center mt-5">
        <button class="btn btn-md btn-secondary mr-2" @click="deletePreferences('week'); show.newEvent = false">{{ openWeekText }}</button>
      </div>
    </confirmation-modal>
  </layout>
</template>

<script>
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  import Layout from '../../Layout.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import companyApi from '../../connections/company';
  import { getCurrentLocaleForVueCal } from '../../../plugins/i18n';

  let time = null;
  export default {
    name: 'AvailabilityPreferences',
    components: { ConfirmationModal, Layout, VueCal },
    props: {
      isPreferred: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        availabilityPreferences: [],
        show: {
          newEvent: false,
        },
        formdata: {
          event: {},
          is_preferred: false
        },
        month: '',
      };
    },
    computed: {
      locale() {
        return getCurrentLocaleForVueCal();
      },
      title() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.preferredDays')
          : this.$t('companyArea.settings.closedDays.closedDays');
      },
      infoText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.info')
          : this.$t('companyArea.settings.closedDays.info');
      },
      infoText2() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.info2')
          : this.$t('companyArea.settings.closedDays.info2');
      },
      infoText3() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.info3')
          : this.$t('companyArea.settings.closedDays.info3');
      },
      closeMonthText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.setPreferredMonth')
          : this.$t('companyArea.settings.closedDays.closeMonth');
      },
      openMonthText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.removePreferredMonth')
          : this.$t('companyArea.settings.closedDays.openMonth');
      },
      closeWeekText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.setPreferredWeek')
          : this.$t('companyArea.settings.closedDays.closeWeek');
      },
      openWeekText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.removePreferredWeek')
          : this.$t('companyArea.settings.closedDays.openWeek');
      },
      legendClickText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.setOrRemovePreferredDay')
          : this.$t('companyArea.settings.closedDays.closeOrOpenDay');
      },
      legendDoubleClickText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.setOrRemovePreferredWeek')
          : this.$t('companyArea.settings.closedDays.closeOrOpenWeek');
      },
      dayStatusText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.dayPreferred')
          : this.$t('companyArea.settings.closedDays.dayClosed');
      },
      dayStatusInfoText() {
        return this.isPreferred
          ? this.$t('companyArea.settings.preferredDays.dayPreferredInfo')
          : this.$t('companyArea.settings.closedDays.dayClosedInfo');
      },
      apiEndpoint() {
        return 'availability-preferences';
      }
    },
    created() {
      this.formdata.is_preferred = this.isPreferred;

      // Rufe alle Verfügbarkeitseinstellungen ab und filtere nach Typ (Sperrtag oder Wunschtag)
      companyApi.get(`companies/${this.$route.params.company_id}/${this.apiEndpoint}?type=${this.isPreferred ? 'preferred' : 'unavailable'}`)
        .then(response => response.data.notificationType === 'success' ?
          this.prepareEvents(response.data.content) : '');
    },
    methods: {
      prepareEvents(events, fresh = false) {
        fresh ? this.availabilityPreferences = [] : '';
        let array = [];
        if(Array.isArray(events)) {
          array = events;
        } else {
          array.push(events);
        }
        for(let i = 0; i < array.length; i++) {
          array[i].start = this.dayjsHelper.dayjs(array[i].day).toDate();
          array[i].end = this.dayjsHelper.dayjs(array[i].day).toDate();

          // Füge Styling-Klassen je nach Typ hinzu
          array[i].class = this.isPreferred ? 'preferred-day' : 'closed-day';

          this.availabilityPreferences.push(array[i]);
        }
      },
      singleClick(event) {
        // first clear time
        clearTimeout(time);
        let that = this;
        time = setTimeout(() => {
          if(that.availabilityPreferences.find(obj => this.dayjsHelper.dayjs(obj.day).isSame(event, 'day'))) {
            that.deletePreferences('single', that.availabilityPreferences.find(obj => this.dayjsHelper.dayjs(obj.day).isSame(event, 'day')));
          } else {
            that.formdata.event = this.dayjsHelper.dayjs(event).format('YYYY-MM-DD');
            that.storePreferences('single');
          }
        }, 300);
      },
      doubleClick(event) {
        if(!this.isPreferred) {
          clearTimeout(time);
          this.show.newEvent = true;
          this.formdata.event = event;
        }
      },
      storePreferences(mode) {
        this.formdata.event = mode === 'month' ? this.dayjsHelper.dayjs(this.month).add(15, 'day') : this.formdata.event;

        companyApi.post(`companies/${this.$route.params.company_id}/${this.apiEndpoint}/store/${mode}`, this.formdata,
            { notification: true, progress: false })
          .then(response => response.data.notificationType === 'success' ?
            mode === 'single' ? this.prepareEvents(response.data.content) : this.prepareEvents(response.data.content, true) : '');
      },
      deletePreferences(mode, preference = null) {
        this.formdata.event = mode === 'month' ? this.dayjsHelper.dayjs(this.month).add(15, 'day') : this.formdata.event;
        let string = mode;
        preference ? string += '/' + preference.id : '';

        companyApi.post(`companies/${this.$route.params.company_id}/${this.apiEndpoint}/delete/${string}`, this.formdata,
            { notification: true, progress: false })
          .then(response => response.data.notificationType === 'success' ?
            mode === 'single' ? this.availabilityPreferences = this.availabilityPreferences.filter(obj => obj.id !== preference.id) :
              this.prepareEvents(response.data.content, true) : '');
      },
    },
  };
</script>
