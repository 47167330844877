<template>
  <div class="relative">
    <label v-if="showLabel" for="zipCity" class="block text-gray-700 mb-1">
      {{ defaultLabel }}
    </label>
    <input id="zipCity"
           v-model="inputValue"
           name="zipCity"
           type="text"
           :class="['w-full', 'mt-1', invalid ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
           :placeholder="placeholder"
           v-bind="useNoAutocomplete()"
           @keyup.down="hoverCityOption('down')"
           @keyup.up="hoverCityOption('up')"
           @keydown.enter.prevent="selectOption(hoveredCityOption)"
           @blur="handleBlur"
           @input="getCityOptions"
           aria-haspopup="listbox"
           :aria-expanded="cityOptions.length > 0"
           aria-autocomplete="list"
    >
    <label-state-icon v-if="props.validationSign" :required="true" :label-state="isValid" />

    <div v-if="cityOptions.length > 0"
         class="absolute top-10 w-full border shadow bg-white font-semibold z-50"
         role="listbox">
      <div v-for="option in listedCityOptions"
           :id="`zipCity-option-${option.id}`"
           :key="`zipCity-${option.id}`"
           class="w-full p-2 cursor-pointer hover:bg-blue-100"
           :class="{ 'bg-blue-100': (cityOptions.indexOf(option) === hoveredCityOption) }"
           :data-cy="`zip-${option.zip}`"
           @click="selectOption(cityOptions.indexOf(option))"
           role="option"
           :aria-selected="cityOptions.indexOf(option) === hoveredCityOption"
      >
        {{ option.zip }} {{ option.city }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onBeforeMount } from 'vue';
  import LabelStateIcon from './ui/LabelStateIcon.vue';
  import { useNoAutocomplete } from '@/composables/useNoAutocomplete.js';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ useScope: 'global' });

  const props = defineProps({
    postcodes: {
      type: Array,
      required: true
    },
    validationSign: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  });

  // Define models for form data binding
  const postcode = defineModel('postcode');
  const city = defineModel('city');
  const postcodeId = defineModel('postcode_id');
  const country = defineModel('country');

  // UI state references
  const cityOptions = ref([]);
  const inputValue = ref('');
  const hoveredCityOption = ref(null);
  const maxCityOptions = ref(3);

  // Initialize component with existing data if available
  onBeforeMount(() => {
    if (!postcodeId.value && postcode.value) {
      getCityOptions('initial');
    }
  });

  // Computed properties
  const isValid = computed(() => Boolean(postcodeId.value));
  const defaultLabel = computed(() => props.label || t('general.zipCity'));

  const placeholder = computed(() => {
    if (postcode.value && city.value) {
      return `${postcode.value} ${city.value}`;
    }
    return '';
  });

  const listedCityOptions = computed(() => {
    let start = 0;
    let end = maxCityOptions.value;

    if (hoveredCityOption.value === null || hoveredCityOption.value < (maxCityOptions.value - 2)) {
      // Keep default range
    } else {
      if ((hoveredCityOption.value - maxCityOptions.value + 2) < (cityOptions.value.length - maxCityOptions.value)) {
        start = hoveredCityOption.value - maxCityOptions.value + 2;
      } else {
        start = cityOptions.value.length - maxCityOptions.value;
      }
      end = start + maxCityOptions.value;
    }

    return cityOptions.value.slice(start, end);
  });

  function clearInput() {
    inputValue.value = '';
    cityOptions.value = [];
    hoveredCityOption.value = null;
  }

  function hoverCityOption(direction) {
    if (direction === 'down') {
      if (hoveredCityOption.value === null) {
        hoveredCityOption.value = 0;
      } else {
        hoveredCityOption.value < cityOptions.value.length - 1 ? hoveredCityOption.value++ : '';
      }
    } else if (direction === 'up') {
      hoveredCityOption.value--;
      hoveredCityOption.value === -1 ? hoveredCityOption.value = null : '';
    }
  }

  function selectOption(index) {
    if (index === null) {
      if (cityOptions.value.length === 1) {
        index = 0;
      } else {
        return;
      }
    }

    updateFormData(cityOptions.value[index]);
  }

  function handleBlur() {
    setTimeout(() => {
      if (cityOptions.value.length > 0) {
        updateFormData(cityOptions.value[0]);
      }
    }, 200);
  }

  function updateFormData(data) {
    if (!data) return;

    postcode.value = data.zip;
    city.value = data.city;
    postcodeId.value = data.id;
    country.value = data.country;
    clearInput();
  }

  // Preserving the original search logic behavior but with cleaner implementation
  function getCityOptions(event = null) {
    if (event === 'initial') {
      // Only necessary when the postcode ID is not known
      const matches = props.postcodes.filter(obj =>
        obj.zip === postcode.value && obj.city === city.value
      );

      if (matches.length > 0) {
        cityOptions.value = matches;
        updateFormData(matches[0]);
      }
      return;
    }

    let filteredOptions = [];

    if (inputValue.value.length > 0) {
      const input = inputValue.value.toLowerCase();

      // First search strategy: direct matching (original behavior)
      filteredOptions = props.postcodes.filter(obj => {
        return obj.zip.startsWith(input) ||
          obj.city.toLowerCase().startsWith(input) ||
          obj.city.toLowerCase().includes(input) ||
          (obj.zip + ' ' + obj.city.toLowerCase()).startsWith(input);
      }).sort((a, b) => {
        const isExactMatchA = a.city.toLowerCase() === input;
        const isExactMatchB = b.city.toLowerCase() === input;
        if (isExactMatchA && !isExactMatchB) {
          return -1;
        }
        if (!isExactMatchA && isExactMatchB) {
          return 1;
        }
        return 0;
      });

      // Second search strategy: without umlauts (if no results from first strategy)
      if (filteredOptions.length === 0) {
        const inputWithoutUmlauts = input
          .replace(/ä/g, 'a')
          .replace(/ö/g, 'o')
          .replace(/ü/g, 'u')
          .replace(/ /g, '');

        filteredOptions = props.postcodes.filter(obj => {
          const cityWithoutUmlauts = obj.city
            .replace(/ä/g, 'a')
            .replace(/ö/g, 'o')
            .replace(/ü/g, 'u')
            .replace(/ /g, '');

          return obj.zip.startsWith(inputWithoutUmlauts) ||
            cityWithoutUmlauts.toLowerCase().startsWith(inputWithoutUmlauts) ||
            cityWithoutUmlauts.toLowerCase().includes(inputWithoutUmlauts) ||
            (obj.zip + ' ' + cityWithoutUmlauts.toLowerCase()).startsWith(inputWithoutUmlauts);
        });
      }

      // Third search strategy: substring from back to front (if still no results)
      if (filteredOptions.length === 0) {
        let inputWithoutUmlauts = input
          .replace(/ä/g, 'a')
          .replace(/ö/g, 'o')
          .replace(/ü/g, 'u');

        // Continue shortening the input until we find matches or run out of characters
        while (inputWithoutUmlauts.length > 0 && filteredOptions.length === 0) {
          filteredOptions = props.postcodes.filter(obj => {
            const cityWithoutUmlauts = obj.city
              .replace(/ä/g, 'a')
              .replace(/ö/g, 'o')
              .replace(/ü/g, 'u').toLowerCase();

            return obj.zip.startsWith(inputWithoutUmlauts) ||
              cityWithoutUmlauts.startsWith(inputWithoutUmlauts) ||
              cityWithoutUmlauts.includes(inputWithoutUmlauts) ||
              (obj.zip + ' ' + cityWithoutUmlauts).startsWith(inputWithoutUmlauts);
          });

          // If no matches, try shortening at space or hyphen
          if (filteredOptions.length === 0) {
            const lastSpace = inputWithoutUmlauts.lastIndexOf(' ');
            const lastHyphen = inputWithoutUmlauts.lastIndexOf('-');
            const cutPoint = Math.max(lastSpace, lastHyphen);

            if (cutPoint !== -1) {
              inputWithoutUmlauts = inputWithoutUmlauts.substring(0, cutPoint);
            } else {
              break; // No more spaces or hyphens to cut at
            }
          }
        }
      }

      // Fourth search strategy: split and search terms individually (if still no results)
      if (filteredOptions.length === 0) {
        let inputWithoutUmlauts = input
          .replace(/ä/g, 'a')
          .replace(/ö/g, 'o')
          .replace(/ü/g, 'u')
          .replace(/ae/g, 'a')
          .replace(/oe/g, 'o')
          .replace(/ue/g, 'u');

        const terms = inputWithoutUmlauts.split(/[\s-]+/).filter(term => term !== '');

        terms.forEach(term => {
          const termResults = props.postcodes.filter(obj => {
            const cityWithoutUmlauts = obj.city
              .replace(/ä/g, 'a')
              .replace(/ö/g, 'o')
              .replace(/ü/g, 'u')
              .replace(/ae/g, 'a')
              .replace(/oe/g, 'o')
              .replace(/ue/g, 'u')
              .toLowerCase();

            return obj.zip.startsWith(term) ||
              cityWithoutUmlauts.startsWith(term) ||
              cityWithoutUmlauts.includes(term);
          });

          // Add unique results only
          termResults.forEach(result => {
            if (!filteredOptions.some(item => item.id === result.id)) {
              filteredOptions.push(result);
            }
          });
        });
      }
    }

    // Reset state when options change
    cityOptions.value = filteredOptions;
    hoveredCityOption.value = null;
  }
</script>
