<template>
  <div class="absolute flex flex-col justify-center items-center inset-0 bg-white/50">
    <div class="spinner-container">
      <div class="sk-chase">
        <div class="sk-chase-dot sk-chase-dot-1" />
        <div class="sk-chase-dot sk-chase-dot-2" />
        <div class="sk-chase-dot sk-chase-dot-3" />
        <div class="sk-chase-dot sk-chase-dot-4" />
        <div class="sk-chase-dot sk-chase-dot-5" />
        <div class="sk-chase-dot sk-chase-dot-6" />
        <div class="sk-chase-dot sk-chase-dot-7" />
      </div>
      <p v-if="message" class="text-center mt-4 text-gray-900 font-bold">{{ message }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Spinner",
    props: {
      message: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped>
    .spinner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>
