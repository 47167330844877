<template>
  <div class="flex justify-between mb-4">
    <div>
      <span class="font-semibold">{{ locationTypeLabel }} <span class="text-red-700">{{ verificationStatus }}</span></span><br>
      {{ location.address }}<br>
      {{ location.postcode }} {{ location.city }}<br><br>

      {{ t('general.Email') }}: {{ location.email }}<br>
      {{ t('general.Phone') }}: {{ location.phone ? location.phone : 'Hauptnummer' }}
    </div>
    <circle-button :icon="['fal', 'pen']" @click="isEditModalOpen = true"/>
  </div>

  <confirmation-modal v-if="isEditModalOpen" @close="isEditModalOpen = false">
    <div class="grid grid-cols-1 gap-4">
      <!-- Address -->
      <base-form-input id="location-address" v-model="formData.address" :label="t('companyArea.components.contact.address')" :errors="errors['address']" required/>

      <!-- Postcode and City in a grid -->
      <zip-city-input v-if="postcodes?.length > 0"
                      v-model:postcode="formData.postcode"
                      v-model:postcode_id="formData.postcode_id"
                      v-model:country="formData.country"
                      v-model:city="formData.city"
                      :postcodes="postcodes"
                      :validation="{}"
                      :validation-sign="true"
                      :show-label="true"
      />

      <base-form-input :container-class="'space-y-0.5'" id="location-email" v-model="formData.email" :label="t('general.Email')" type="email" :errors="errors['email']" required/>
      <base-form-input id="location-phone" v-model="formData.phone" :label="t('general.Phone')" :errors="errors['phone']"/>
    </div>

    <div class="flex justify-center mt-3">
      <button class="btn btn-lg btn-secondary" @click="saveLocation">{{ updateLocationIsPending ? t('general.submitting') : t('general.save') }}</button>
    </div>
  </confirmation-modal>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import ConfirmationModal from '@snippets/ConfirmationModal.vue';
  import CircleButton from '@snippets/CircleButton.vue';
  import notification from '@components/connections/notification';
  import ZipCityInput from '@components/inputs/zipCityInput.vue';
  import { useI18n } from 'vue-i18n';
  import { useMutation } from '@tanstack/vue-query';
  import { createMutationFn } from '@/api/api.js';
  import { useQuery } from '@tanstack/vue-query';
  import { usePostcodesQuery } from '@/api/queries/location.js';

  const { t } = useI18n({ useScope: 'global' });

  // Props instead of model
  const props = defineProps({
    location: {
      type: Object,
      required: true
    },
  });

  // Store access
  const store = useStore();
  const { data: postcodes = [] } = useQuery(usePostcodesQuery())

  // Reactive state
  const isEditModalOpen = ref(false);
  const errors = ref({});

  // Create a local copy of the location data for the form
  const formData = ref({ ...props.location });

  // Computed properties
  const locationTypeLabel = computed(() => {
    return props.location.headquarter ? t('companyArea.settings.contact.mainLocation') : t('companyArea.settings.contact.location');
  });

  const verificationStatus = computed(() => {
    return props.location.verified_at ? '' : t('companyArea.settings.snippets.location.notVerifiedYet');
  });

  const isLastLocation = computed(() => {
    return store.state.company.locations.length <= 1;
  });

  // Reset form data when modal is opened
  const openEditModal = () => {
    formData.value = { ...props.location };
    isEditModalOpen.value = true;
  };

  // Methods
  const saveLocation = () => {
    // Validation
    if (!formData.value.email || !formData.value.address ||
      !formData.value.postcode || !formData.value.city) {
      notification.warning(t('general.fieldsMissing'));
      return;
    }

    updateLocation(formData.value);
  };

  // Mutations using TanStack Query
  const {
    isPending: updateLocationIsPending,
    isError: updateLocationHasError,
    isSuccess: updateLocationIsSuccess,
    error: updateLocationError,
    mutate: updateLocation,
  } = useMutation({
    mutationFn: (data) => createMutationFn('company', 'patch', `/locations/${data.id}`)(data),
    onSuccess: (data) => {
      console.log('Location updated', data);
      if (data.content) {
        isEditModalOpen.value = false;
        store.commit('company/setLocations', data.content);
        // Emit update event to inform parent of the change
        emit('update', data.content);
      }
    },
    onError: (error) => {
      if (error.response?.status === 422) {
        errors.value = error.response.data.errors || {};
      }
    },
  });
</script>
