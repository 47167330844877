<template>
  <div class="relative mt-4 rounded shadow-md p-4 bg-white w-full overflow-hidden">
    <div class="sm:flex sm:justify-between ">
      <div>
        <div class="flex justify-between sm:block">
          <div>
            <div class="text-lg sm:text-xl text-left font-semibold sm:font-extrabold">{{ companyPublicName(company) }}</div>
            <div v-if="company.company_profile && company.company_profile.logo && company.company_profile.link_partner_at" class="mt-4">
              <img :src="company.company_profile.logo" style="max-width: 250px; max-height: 70px;">
            </div>
            <div class="text-md sm:text-sm mt-2">
              {{ location.postcode }} {{ location.city }}<br>{{ $t('customerArea.quoteOverview.company.phone') }}:
              {{ location.phone ?? company.phone }}
            </div>
          </div>
          <div v-if="company.partner_status">
            <div class="absolute -top-36 -right-36">
              <img :src="imagePath" @click="show.partnerStatusInfo = true" class="cursor-pointer mt-4 w-80 opacity-10 bg-white">
            </div>
          </div>

        </div>
        <div class="flex sm:hidden items-end justify-between">
          <div class="cursor-pointer mt-3" @click="routeTo('CustomerReviews')">
            <rating-topofferten :review="company.company_reviews.length > 0 ? company.review_avg : 0"
                                class="w-40"
            />
            <div v-if="company.company_reviews.length > 0" class="text-gray-500 mt-2">Ø {{ company.review_avg }} / Total {{ company.company_reviews.length }}</div>
          </div>
          <div v-if="company.partner_status" class="w-28 mb-3">
            <img :src="imagePath" @click="show.partnerStatusInfo = true" class="cursor-pointer mt-4 w-28">
          </div>
        </div>
        <div class="flex flex-wrap mt-5 sm:mt-3 pl-2 justify-center sm:justify-start">
          <a :href="'tel:' + company.phone" class="sm:hidden">
            <round-button :icon="['fal','phone-volume']" :label="$t('customerArea.quoteOverview.company.phone')" @click=""/>
          </a>
          <a v-if="companyLead.website.url" :href="companyLead.website.url" target="_blank">
            <round-button :icon="['fal','globe']" :label="$t('customerArea.quoteOverview.company.website')"/>
          </a>
          <a :href="'mailto:' + location.email?? company.email">
            <round-button :icon="['fal','at']" :label="$t('customerArea.quoteOverview.company.email')"/>
          </a>
          <a v-if="company.uid_link" :href="company.uid_link" target="_blank">
            <round-button :icon="['fal','check-circle']" :label="$t('customerArea.quoteOverview.company.register')"/>
          </a>
          <round-button :icon="['fal','star']"
                        :active="show.companyReviews"
                        :label="$t('customerArea.quoteOverview.company.reviews')"
                        data-cy="icon-menu-reviews"
                        @click="routeTo('CustomerReviews')"
          />
          <round-button v-if="3 === 2" :icon="['fal','calendar']" :label="$t('customerArea.quoteOverview.company.appointements')"/>
          <round-button :icon="['fal', 'comments']"
                        :active="show.chat"
                        :text-color="companyLead.quote_logs.length > 0 ? 'text-green-500' : 'text-black'"
                        :label="$t('customerArea.quoteOverview.company.chat')"
                        data-cy="icon-menu-chat"
                        @click="routeTo('CustomerChat')"
          />
        </div>
      </div>
      <div class="hidden sm:block">
        <div v-if="company.partner_status" class="w-48 flex justify-center mb-3">
          <img :src="imagePath" @click="show.partnerStatusInfo = true" class="cursor-pointer mt-4 w-48">
        </div>
        <div class="flex justify-center flex-col items-center cursor-pointer" @click="routeTo('CustomerReviews')">
          <rating-topofferten :review="company.company_reviews.length > 0 ? company.review_avg : 0"
                              class="w-40"
          />
          <div v-if="company.company_reviews.length > 0" class="text-gray-500 mt-2">Ø {{ company.review_avg }} / Total {{ company.company_reviews.length }}</div>
        </div>
      </div>
    </div>
    <div v-if="quote" class="flex justify-between mt-2 sm:justify-start">
      <div class="sm:mr-10">
        <div>
          {{ $t('general.offerte') }} {{ $t('general.date.from') }}
          {{ this.dayjsHelper.showDate(quote.created_at) }}
        </div>
        <div class="text-base font-extrabold overflow-visible cursor-pointer"
             @click="showQuote"
        >
          {{
            quote.type === 'fixprice' ? $t('customerArea.quoteOverview.flippingCard.fixprice') : $t('customerArea.quoteOverview.flippingCard.free')
          }}
          <span class="relative overflow-visible">
              <img class="max-w-none absolute -top-1 -left-1"
                   src="../../../assets/images/price-swash.svg"
                   :class="quote.type === 'estimated' && quote.price_2 ? 'w-44' : 'w-36'"
              >
              <span class="relative" :data-cy="'quote-price-' + companyLead.company_id">
                <quote-price :quote="quote"/>
              </span>
            </span>
        </div>
      </div>
      <div class="flex flex-col z-10">
        <circle-button :icon="['fas','caret-down']"
                       class="mb-1"
                       :class="{ 'active':(show.quote)}"
                       :data-cy="'show-quote-' + companyLead.company_id"
                       @click="showQuote"
        />
      </div>
    </div>

    <quote :company-lead="companyLead"
           :quote="quote"
           :show-quote="show.quote"
           :desktop="desktop"
    />
    <!--      <customer-buttons v-if="this.dayjsHelper.isOlderThanInDays(companyLead.created_at,1)" :company-lead="companyLead" />-->
    <!--      <company-tags v-if="![1,2].includes(companyLead.status) || companyLead.lead.review_mail_sent_at"-->
    <!--                    :company-lead="companyLead"-->
    <!--                    :company-tag="companyLead.lead.company_tags.find(obj => obj.company_id === companyLead.company_id)"-->
    <!--      />-->
    <!--      <img :src="company.company_profile.logo.url" style="width: 180px;" class="cursor-pointer mt-4">-->
    <div>

    </div>

    <confirmation-modal v-if="show.partnerStatusInfo" :content-size="true" @close="show.partnerStatusInfo = false">
      <div class="font-semibold">{{ company.name }}</div>
      <div class="flex border-t pt-2 mt-2">
        <rating v-if="ratingComponent === 'Rating'"
                :review="company.ps_avg > 0 ? company.ps_avg : 0"
                class="text-xs"
        />
        <rating-topofferten v-else
                            :review="company.ps_avg > 0 ? company.ps_avg : 0"
                            class="w-24"
                            style="height:17px"
        />
        <div v-if="company.ps_count > 0" class="ml-2 text-gray-500">Ø {{ company.ps_avg }} / Total {{ company.ps_count }}</div>
      </div>

      <div class="text-sm mt-5 " v-html="$t('customerArea.quoteOverview.flippingCard.partnerStatusInfo')"/>
    </confirmation-modal>
  </div>
</template>

<script>
  import { companyMixin } from '../../../plugins/mixin';
  import CircleButton from '../../snippets/CircleButton.vue';
  import Quote from './Quote.vue';
  import Company from './Company.vue';
  import QuotePrice from '../../sharedComponents/QuotePrice.vue';
  import CustomerButtons from './CustomerButtons.vue';
  import CompanyTags from './CompanyTags.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import RatingTopofferten from '../../snippets/RatingTopofferten.vue';
  import Rating from '../../snippets/Rating.vue';
  import RoundButton from '../../snippets/RoundButton.vue';

  export default {
    name: 'CompanyCard',
    components: {
      RoundButton,
      Rating, RatingTopofferten,
      ConfirmationModal,
      CompanyTags,
      CustomerButtons,
      QuotePrice,
      Company,
      Quote,
      CircleButton,
    },
    mixins: [companyMixin],
    props: {
      companyLead: Object,
      desktop: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        rendered: false,
        flipped: false,
        backside: false,
        show: {
          quote: false,
          flipped: true,
          backside: false,
          partnerStatusInfo: false,
        },
        height: '',
        company: this.companyLead.company,
        location: this.getCompanyLocation(this.companyLead.website.locations, this.companyLead.postcode_id),
        ratingComponent: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany' ? 'Rating' : 'RatingTopofferten',
        images: import.meta.glob('../../../assets/images/topofferten/*-partner.jpg'),
        imagePath: '',
      };
    },
    computed: {
      quote() {
        // in companyView companyLead is a fake Object
        if (this.companyLead.hasOwnProperty('id')) {
          return this.getQuote();
        } else {
          return null;
        }
      },
    },
    watch: {
      companyLead: {
        handler() {
          if (this.rendered) {
            this.loadImagePath();
          }
        }, deep: true,
      },
    },
    mounted() {
      this.rendered = true;
      this.loadImagePath();
    },
    methods: {
      showQuote() {
        this.show.quote = !this.show.quote;
      },
      routeTo(route) {
        // in companyView these links do not work and companyLead is a fake Object
        if (this.companyLead.hasOwnProperty('id')) {
          this.$router.push({ name: route, params: { lead_uuid: this.companyLead.lead_uuid, customer_company_id: this.companyLead.company_id } }).catch(err => {
          });
        }
      },
      async loadImagePath() {
        if (this.company.partner_status) {
          this.imagePath = await this.getImagePath(this.company.partner_status);
        }
      },
      async getImagePath(status) {
        const path = `../../../assets/images/topofferten/${status}-partner.jpg`;
        if (this.images[path]) {
          const module = await this.images[path]();
          return module.default;
        }
        return '';
      },
    },
  };
</script>
