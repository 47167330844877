<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Shop</p>
    </template>

    <div class="w-full p-2 ">
      <div class="panel">
        <div class="font-semibold"><span class="text-red-600">Kostenlose Lieferung</span> - Profi Umzugskartons - Hergestellt in der Schweiz - <span class="text-red-600">Bester Deal</span> (siehe <a
          href="https://www.comparis.ch/immobilien/umzug/vor-dem-umzug/umzugskartons-kaufen" target="_blank"
        >Comparis-Vergleich</a>)
        </div>
      </div>
    </div>

    <div class="flex flex-col-reverse md:flex-row md:justify-between w-full">

      <div class="flex flex-wrap mt-5">
        <order-product v-for="product in products"
                       :key="product.id"
                       :product="product"
                       :area="area"
        />
      </div>
      <div class="w-full md:w-1/3 lg:w-1/4 p-2 mt-3">
        <div class="bg-white border rounded p-4">
          <cart-overview :checkout="$route.meta.area + 'Cart'"/>
        </div>
      </div>
    </div>

    <pick-up-points/>
  </layout>
</template>

<script>

  import { defineComponent } from 'vue';
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import PickUpPoints from './Shop/PickUpPoints.vue';
  import CartOverview from '../sharedComponents/CartOverview.vue';
  import OrderProduct from './Shop/OrderProduct.vue';

  export default defineComponent({
    name: 'Shop',
    components: { OrderProduct, CartOverview, PickUpPoints, Layout },
    data() {
      return {};
    },
    computed: {
      isCompanyArea() {
        return this.$route.meta.area === 'Company';
      },
      area() {
        return this.isCompanyArea ? 'Company' : 'Customer';
      },
      products() {
        return store.state.shop.customerProducts;
      },
    },
    beforeCreate() {
      store.dispatch('shop/getCustomerShopProducts');
    },
    created() {
      store.dispatch('shop/getOrderFromLocalStorage', this.area);
    },
  });
</script>

<style scoped>

</style>
