<template>
  <div class="website-input-container">
    <!-- Website input field -->
    <base-form-input
      v-if="!noWebsite"
      :id="id"
      v-model="websiteUrl"
      :label="defaultLabel"
      :errors="validationErrors"
      :is-valid="isValid"
      :help-text="helpText"
      :container-class="containerClass"
      :input-class="inputClass"
      :placeholder="placeholder"
      :required="required"
      :show-icon="showIcon"
      :loading="isPending"
      @blur="validateWebsite"
    />

    <!-- "No website yet" checkbox -->
    <div class="flex items-center mt-2">
      <input
        :id="`${id}-no-website`"
        v-model="noWebsite"
        type="checkbox"
        class="mt-2 mr-2 mb-3"
        @change="handleNoWebsiteChange"
      >
      <label :for="`${id}-no-website`" class="text-gray-700">
        {{ defaultNoWebsiteLabel }}
      </label>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import { useI18n } from 'vue-i18n';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import { companyApiVueQuery } from '@/api/api.js';

  const { t } = useI18n({ useScope: 'global' });

  // Model for two-way binding
  const websiteModel = defineModel();

  // Props
  const props = defineProps({
    id: { type: String, default: 'website', },
    label: { type: String, default: '', },
    helpText: { type: String, default: '', },
    required: { type: Boolean, default: true, },
    placeholder: { type: String, default: '', },
    containerClass: { type: String, default: '', },
    inputClass: { type: String, default: '', },
    showIcon: { type: Boolean, default: true, },
    initialErrors: { type: Array, default: () => null, },
    initialNoWebsite: { type: Boolean, default: false },
    noWebsiteValue: { type: String, default: 'in-progress' },
    noWebsiteLabel: { type: String, default: '' }
  });

  // Emits
  const emit = defineEmits([
    'validation-started',
    'validation-success',
    'validation-error',
    'website-changed',
    'no-website-changed',
  ]);

  // Reactive state
  const websiteUrl = ref('');
  const noWebsite = ref(false);
  const validationErrors = ref(props.initialErrors);
  const websiteLastValue = ref('');
  const isValidating = ref(false);
  const savedWebsiteValue = ref(''); // Store website value when checkbox is toggled

  // Computed properties
  const defaultLabel = computed(() => props.label || t('general.website'));
  const defaultNoWebsiteLabel = computed(() => props.noWebsiteLabel || t('general.noWebsiteYet'));
  const isValid = computed(() => {
    // If no website is checked, it's always valid
    if (noWebsite.value) return true;
    // Otherwise validate normally
    return Boolean(websiteUrl.value) && !validationErrors.value;
  });

  // Initialize states
  function initializeState() {
    // Check if the model value matches the noWebsiteValue
    if (websiteModel.value === props.noWebsiteValue) {
      noWebsite.value = true;
      savedWebsiteValue.value = ''; // No previous website to save
    } else {
      noWebsite.value = props.initialNoWebsite;
      websiteUrl.value = websiteModel.value || '';
      websiteLastValue.value = websiteUrl.value;
    }
  }

  // Watch for changes to synchronize the model
  watch(websiteUrl, (newValue) => {
    if (!noWebsite.value) {
      websiteModel.value = newValue;
      emit('website-changed', newValue);
    }
  });

  // Watch for external model changes
  watch(() => websiteModel.value, (newValue) => {
    if (newValue === props.noWebsiteValue) {
      if (!noWebsite.value) {
        noWebsite.value = true;
        savedWebsiteValue.value = websiteUrl.value || '';
      }
    } else if (newValue !== websiteUrl.value && !noWebsite.value) {
      websiteUrl.value = newValue;
      websiteLastValue.value = newValue;
    }
  });

  // Watch for external error changes
  watch(() => props.initialErrors, (newErrors) => {
    validationErrors.value = newErrors;
  });

  // Handle the "no website" checkbox change
  function handleNoWebsiteChange() {
    if (noWebsite.value) {
      // Save current website value and set the special value
      savedWebsiteValue.value = websiteUrl.value || '';
      websiteModel.value = props.noWebsiteValue;
      validationErrors.value = null; // Clear any validation errors
    } else {
      // Restore previous website value if available
      websiteUrl.value = savedWebsiteValue.value;
      websiteModel.value = savedWebsiteValue.value;
      // If we're restoring to a non-empty value, we might want to validate it
      if (savedWebsiteValue.value) {
        validateWebsite();
      }
    }

    emit('no-website-changed', noWebsite.value);
  }

  // Website validation mutation
  const { isPending, isError, error, isSuccess, mutate } = useMutation({
    mutationFn: (payload) => {
      emit('validation-started');
      return companyApiVueQuery.post('/url-checker', payload, { notification: false, progress: false });
    },

    onSuccess: handleUrlCheckSuccess,
    onError: handleUrlCheckError,
    onSettled: () => { isValidating.value = false; }
  });

  // Validation function
  function validateWebsite() {
    if (noWebsite.value) return; // Skip validation if "no website" is checked

    if (websiteUrl.value && websiteLastValue.value !== websiteUrl.value) {
      validationErrors.value = null;
      isValidating.value = true;
      mutate({ websiteTemp: websiteUrl.value });
    }
    websiteLastValue.value = websiteUrl.value;
  }

  function handleUrlCheckSuccess(response) {
    if (response.data.notificationType === 'success' && response.data.content.valid) {
      validationErrors.value = null;
      websiteUrl.value = response.data.content.url;
      emit('validation-success', response.data.content.url);
    } else {
      validationErrors.value = [t('publicArea.registerCompany.steps.registerUserWithCompany.warningWebsite')];
      emit('validation-error', validationErrors.value);
    }
  }

  function handleUrlCheckError() {
    validationErrors.value = [t('publicArea.registerCompany.steps.registerUserWithCompany.warningWebsite')];
    emit('validation-error', validationErrors.value);
  }

  initializeState();
</script>
