<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.contact.contact') }}</p>
    </template>

    <div class="panel">
      <!-- Warning messages -->
      <div v-if="!company.uid" class="w-full bg-red-600 font-semibold text-center text-white p-1 rounded mb-4">
        {{ $t('companyArea.settings.contact.enterUid') }}
      </div>
      <div v-if="company.register_invalid_at && company.register_status_aktiv === true" class="w-full bg-red-600 font-semibold text-center text-white p-1 rounded mb-4">
        {{ $t('companyArea.settings.contact.registerNameWrong') }}
      </div>
      <div v-if="company.register_invalid_at && company.register_status_aktiv === false" class="w-full bg-red-600 font-semibold text-center text-white p-1 rounded mb-4">
        {{ $t('companyArea.settings.contact.registerDisabled') }}
      </div>

      <!-- Company Info Grid -->
      <div class="grid grid-cols-2 gap-2">
        <div class="border-b pb-1">
          <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.officialCompanyName') }}</span><br>
          <span class="text-gray-500 text-xs">{{ $t('companyArea.settings.contact.billNameInfo') }}</span>
        </div>
        <div class="border-b text-base font-semibold pb-1">{{ company.name }}</div>

        <div class="border-b mt-3 pb-1">
          <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.companyLabel') }}</span><br>
          <span class="text-gray-500 text-xs">{{ $t('companyArea.settings.contact.companyLabelInfo') }}</span>
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1">{{ company.label }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.phone') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1">{{ company.phone }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.email') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1">{{ company.email }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.website') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1">{{ website.url }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1" :class="{ 'w-full bg-red-600 font-semibold text-center text-white p-1 rounded': hasUidProblem }">
          {{ $t('companyArea.settings.contact.register') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 flex justify-between pb-1">
          <span>{{ company.uid }}</span>
          <circle-button
            :icon="['fal', 'pen']"
            size="xs"
            @click="showUidEdit = !showUidEdit"
          />
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="mt-4 flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
        <button class="btn btn-sm btn-primary mt-3 sm:mt-0 w-full sm:w-auto" @click="showCompanyEdit = !showCompanyEdit">
          {{ $t('companyArea.settings.contact.editContact') }}
        </button>

        <button class="btn btn-sm btn-secondary mt-3 sm:mt-0 w-full sm:w-auto" @click="showPassword = !showPassword">
          {{ $t('companyArea.settings.contact.changePassword') }}
        </button>

        <a
          v-if="company.uid_link"
          class="btn btn-sm btn-secondary mt-3 sm:mt-0 w-full sm:w-auto block text-center"
          :class="{ 'bg-red-600 text-white': hasUidProblem }"
          :href="company.uid_link"
          target="_blank"
        >
          {{ $t('companyArea.settings.contact.showUidLink') }}
        </a>

        <a
          v-if="company.hr_link"
          class="btn btn-sm btn-secondary mt-3 sm:mt-0 w-full sm:w-auto block text-center"
          :class="{ 'bg-red-600 text-white': hasUidProblem }"
          :href="company.hr_link"
        >
          {{ $t('companyArea.settings.contact.showHRLink') }}
        </a>
      </div>

      <!-- Edit Company Modal -->
      <confirmation-modal v-if="showCompanyEdit" @close="showCompanyEdit = false">
        <!-- Name (admin only) -->
        <base-form-input
          v-if="user.is_admin"
          id="company-name"
          v-model="formData.name"
          :label="$t('companyArea.settings.contact.officialCompanyName')"
          :help-text="$t('companyArea.settings.contact.billNameInfo')"
          required
        />

        <!-- Label -->
        <base-form-input
          class="mt-4"
          id="company-label"
          v-model="formData.label"
          :label="$t('companyArea.settings.contact.companyLabel')"
          :help-text="$t('companyArea.settings.contact.companyLabelInfo')"
          :show-icon="false"
        />

        <!-- Email -->
        <email-input
          class="mt-4"
          id="company-email"
          v-model="formData.email"
          required
          @validation-success="handleEmailValidationSuccess"
          @validation-error="handleEmailValidationError"
        />

        <!-- Phone -->
        <phone-input
          class="mt-4"
          id="company-phone"
          v-model="formData.phone"
          required
          @validation-success="handlePhoneValidationSuccess"
          @validation-error="handlePhoneValidationError"
        />

        <!-- Website -->
        <website-input
          class="mt-4"
          id="company-website"
          v-model="formData.website"
          required
          @validation-success="handleWebsiteValidationSuccess"
          @validation-error="handleWebsiteValidationError"
          @no-website-changed="handleNoWebsiteChange"
          @validation-started="handleWebsiteValidationStarted"
        />

        <div class="flex justify-center mt-4">
          <button
            class="btn btn-lg btn-secondary"
            @click="updateCompany(formData)"
            :disabled="updateCompanyIsPending || websiteValidationIsPending"
          >
            {{ saveButtonText }}
          </button>
        </div>
      </confirmation-modal>

      <!-- UID Edit Modal -->
      <confirmation-modal v-if="showUidEdit" @close="showUidEdit = false">
        <check-register :company="company" @close="showUidEdit = false" />
      </confirmation-modal>

      <!-- Password Change Modal -->
      <confirmation-modal v-if="showPassword" :content-size="true" @close="showPassword = false">
        <div class="space-y-4">
          <base-form-input
            id="password-old"
            v-model="passwordData.password_old"
            :label="$t('companyArea.settings.contact.oldPassword')"
            type="password"
            required
          />

          <base-form-input
            id="password-new"
            v-model="passwordData.password_new"
            :label="$t('companyArea.settings.contact.newPassword')"
            type="password"
            required
          />

          <base-form-input
            id="password-confirm"
            v-model="passwordData.password_confirm"
            :label="$t('companyArea.settings.contact.confirmPassword')"
            type="password"
            required
          />
        </div>

        <div class="flex justify-center mt-4">
          <button
            class="btn btn-lg btn-secondary"
            @click="changePassword"
            :disabled="passwordChangeInProgress"
          >
            {{ passwordChangeInProgress ? $t('general.loading') : $t('general.save') }}
          </button>
        </div>
      </confirmation-modal>

      <!-- Locations Section -->
      <div class="flex justify-between mt-5">
        <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.locations') }}</span>
        <circle-button
          v-if="user.is_admin"
          :icon="['fal', 'plus']"
          @click="showAddLocation = !showAddLocation"
        />
      </div>

      <!-- Location Components -->
      <location
        v-for="(location, index) in locations"
        :key="location.id"
        :location="location"
      />

      <!-- Add Location Modal -->
      <confirmation-modal v-if="showAddLocation" @close="showAddLocation = false">
        <div class="grid grid-cols-1 gap-4">
          <base-form-input
            id="new-location-address"
            v-model="newLocation.address"
            :label="$t('companyArea.components.contact.address')"
            required
          />

          <zip-city-input v-if="postcodes?.length > 0"
                          v-model:postcode="newLocation.postcode"
                          v-model:postcode_id="newLocation.postcode_id"
                          v-model:country="newLocation.country"
                          v-model:city="newLocation.city"
                          :postcodes="postcodes"
                          :validation="{}"
                          :validation-sign="true"
                          :show-label="true"
          />

          <email-input
            id="new-location-email"
            v-model="newLocation.email"
            :label="$t('general.Email')"
            required
            @validation-success="(email) => newLocation.email = email"
            @validation-error="(errors) => locationErrors.email = errors"
          />

          <phone-input
            id="new-location-phone"
            v-model="newLocation.phone"
            :label="$t('general.Phone')"
            @validation-success="(phone) => newLocation.phone = phone"
            @validation-error="(errors) => locationErrors.phone = errors"
          />
        </div>

        <div class="flex justify-center mt-3">
          <button
            class="btn btn-primary btn-md"
            @click="submitAddLocation"
            :disabled="addLocationIsPending"
          >
            {{ addLocationIsPending ? $t('general.loading') : $t('companyArea.settings.contact.addLocation') }}
          </button>
        </div>
      </confirmation-modal>
    </div>
  </layout>
</template>

<script setup>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useMutation, useQuery } from '@tanstack/vue-query';
  import { useStore } from 'vuex';
  import Layout from '../../Layout.vue';
  import Location from '../Settings/snippets/Location.vue';
  import CircleButton from '@snippets/CircleButton.vue';
  import ConfirmationModal from '@snippets/ConfirmationModal.vue';
  import CheckRegister from './snippets/CheckRegister.vue';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import EmailInput from '@/components/inputs/EmailInput.vue';
  import PhoneInput from '@/components/inputs/PhoneInput.vue';
  import WebsiteInput from '@/components/inputs/WebsiteInput.vue';
  import { createMutationFn } from '@/api/api.js';
  import notification from '@connections/notification';
  import { useI18n } from 'vue-i18n';
  import ZipCityInput from '@components/inputs/zipCityInput.vue';
  import { usePostcodesQuery } from '@/api/queries/location.js';

  const store = useStore();
  const { t } = useI18n({ useScope: 'global' });

  // Reactive state
  const showAddLocation = ref(false);
  const showCompanyEdit = ref(false);
  const showPassword = ref(false);
  const showUidEdit = ref(false);
  const websiteValidationIsPending = ref(false);
  const passwordChangeInProgress = ref(false); // New ref for password change state
  const formErrors = ref({});
  const locationErrors = ref({});

  const { data: postcodes = [] } = useQuery(usePostcodesQuery())

  // Form data
  const formData = ref({
    companyId: store.state.company.company.id,
    name: store.state.company.company.name,
    phone: store.state.company.company.phone,
    email: store.state.company.company.email,
    uid: store.state.company.company.uid,
    label: store.state.company.company.label,
    website: store.state.company.website.url,
  });

  const saveButtonText = computed(() => {
    if (websiteValidationIsPending.value === true) {
      return t('companyArea.settings.contact.validating');
    } else {
      return updateCompanyIsPending.value ? t('general.submitting') : t('general.save');
    }
  });


  // Update form data when store changes
  watch(() => store.state.company.company, (newCompany) => {
    if (newCompany) {
      formData.value = {
        companyId: newCompany.id,
        name: newCompany.name,
        phone: newCompany.phone,
        email: newCompany.email,
        uid: newCompany.uid,
        label: newCompany.label,
        website: store.state.company.website.url,
      };
    }
  }, { immediate: true });

  const passwordData = ref({
    password_old: '',
    password_new: '',
    password_confirm: '',
  });

  const newLocation = ref({
    website_id: store.state.company.website.id,
    email: store.state.company.company.email,
    address: '',
    postcode: '',
    city: '',
    postcode_id: null,
    state_code: '',
    phone: '',
  });

  // Mutations using TanStack Query
  const {
    isPending: updateCompanyIsPending,
    isError: updateCompanyHasError,
    isSuccess: updateCompanyIsSuccess,
    mutate: updateCompany
  } = useMutation({
    mutationFn: (data) => createMutationFn('company', 'patch', `/companies/${data.companyId}`,)(data),
    onSuccess: (data) => {
      showCompanyEdit.value = false;
      if (data.content && data.content.company) {
        store.commit('company/setCompany', data.content.company);
      }
    }
  });

  const {
    isPending: addLocationIsPending,
    isError: addLocationIsError,
    isSuccess: addLocationIsSuccess,
    error: addLocationError,
    mutate: addLocationMutation,
  } = useMutation({
    mutationFn: (data) => createMutationFn(
      'company',
      'post',
      `/companies/${store.state.company.company.id}/locations/`,
      {
        showProgress: true,
        showSuccessNotification: true,
        showErrorNotification: true
      }
    )(data),
    onSuccess: (data) => {
      showAddLocation.value = false;
      if (data.content && data.content.company) {
        store.commit('company/setInitialCompanyData', data.content.company);

        // Reset form
        newLocation.value = {
          website_id: store.state.company.website.id,
          email: store.state.company.company.email,
          address: '',
          postcode: '',
          city: '',
          postcode_id: null,
          state_code: '',
          phone: '',
        };
        // Reset errors
        locationErrors.value = {};
      }
    }
  });

  const changePasswordMutation = useMutation({
    mutationFn: (data) => createMutationFn(
      'auth',
      'post',
      `/users/${store.state.main.user.id}/change-password/`,
      {
        showProgress: true,
        showSuccessNotification: true,
        showErrorNotification: true,
        errorMessage: t('companyArea.settings.contact.passwordChangeFailed')
      }
    )(data),
    onSuccess: () => {
      passwordChangeInProgress.value = false; // Reset loading state
      showPassword.value = false;

      // Reset form
      passwordData.value = {
        password_old: '',
        password_new: '',
        password_confirm: '',
      };
    },
    onError: () => {
      // Make sure to reset loading state on error too
      passwordChangeInProgress.value = false;
    }
  });

  // Computed properties - use existing store data
  const company = computed(() => store.state.company.company);
  const locations = computed(() => store.state.company.locations);
  const website = computed(() => store.state.company.website);
  const user = computed(() => store.state.main.user);

  const hasUidProblem = computed(() => {
    return (!company.value.uid || company.value.register_invalid_at);
  });

  // Lifecycle hooks
  onMounted(() => {
    if (company.value.uid_name_check) {
      showUidEdit.value = true;
    }
  });

  // Form validation handlers
  function handleEmailValidationSuccess(validatedEmail) {
    formErrors.value.email = null;
    formData.value.email = validatedEmail;
  }

  function handleEmailValidationError(emailErrors) {
    formErrors.value.email = emailErrors;
  }

  function handlePhoneValidationSuccess(validatedPhone) {
    formErrors.value.phone = null;
    formData.value.phone = validatedPhone;
  }

  function handlePhoneValidationError(phoneErrors) {
    formErrors.value.phone = phoneErrors;
  }

  function handleWebsiteValidationStarted(validatedUrl) {
    formErrors.value.website = null;
    websiteValidationIsPending.value = true;
  }

  function handleWebsiteValidationSuccess(validatedUrl) {
    formErrors.value.website = null;
    formData.value.website = validatedUrl;
    websiteValidationIsPending.value = false;
  }

  function handleWebsiteValidationError(websiteErrors) {
    formErrors.value.website = websiteErrors;
    websiteValidationIsPending.value = false;
  }

  function handleNoWebsiteChange(noWebsiteChecked) {
    // Handle any special logic when the "no website" checkbox changes
  }

  function submitAddLocation() {
    // Check if there are any validation errors in location form
    if (Object.values(locationErrors.value).some(error => error !== null && error !== undefined)) {
      notification.warning(t('general.correctErrors'));
      return;
    }

    if (!newLocation.value.email || !newLocation.value.address ||
      !newLocation.value.postcode || !newLocation.value.city) {
      notification.warning(t('general.fieldsMissing'));
      return;
    }

    addLocationMutation(newLocation.value);
  }

  function changePassword() {
    if (passwordData.value.password_new !== passwordData.value.password_confirm) {
      notification.warning(t('companyArea.settings.contact.password2Wrong'));
      return;
    }

    if (passwordData.value.password_new.length < 8) {
      notification.warning(t('companyArea.settings.contact.passwordShort'));
      return;
    }

    // Set loading state explicitly
    passwordChangeInProgress.value = true;

    // Use the mutation
    changePasswordMutation.mutate(passwordData.value);
  }
</script>