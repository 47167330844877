import { getApiClient } from './clients/apiClient';
import { applyDefaultMiddleware } from './clients/axiosMiddleware';
import notification from '@connections/notification';
import { i18n } from '@/main.js';

// Apply middleware to our API clients
const enhancedAuthApi = applyDefaultMiddleware(getApiClient('auth'));
const enhancedCompanyApi = applyDefaultMiddleware(getApiClient('company'));
const enhancedLeadFormApi = applyDefaultMiddleware(getApiClient('leadForm'));
const enhancedLocationApi = applyDefaultMiddleware(getApiClient('location'));

/**
 * Create a query function compatible with TanStack Query
 * @param {string} endpoint - The endpoint name
 * @param {string} method - The HTTP method (get, post, put, patch, delete)
 * @param {string} url - The URL path
 * @param {Object} options - Additional options including default params/data
 * @returns {function} A query function for TanStack Query
 */
export const createQueryFn = (endpoint, method, url, options = {}) => {
  return async ({ queryKey, signal, meta = {} }) => {
    const [_key, paramsInput = {}] = queryKey;

    // Make sure params is an object
    const params = typeof paramsInput === 'object' && paramsInput !== null
      ? paramsInput
      : {};

    // Get the appropriate enhanced API client
    let apiClient;
    switch (endpoint) {
      case 'auth':
        apiClient = enhancedAuthApi;
        break;
      case 'company':
        apiClient = enhancedCompanyApi;
        break;
      case 'leadForm':
        apiClient = enhancedLeadFormApi;
        break;
      case 'location':
        apiClient = enhancedLocationApi;
        break;
      default:
        apiClient = applyDefaultMiddleware(getApiClient(endpoint));
    }

    const config = {
      method,
      url,
      params: method === 'get' ? { ...options.params, ...params } : options.params,
      data: method !== 'get' ? { ...options.data, ...params } : undefined,
      signal,
      ...options,
      ...meta
    };

    const response = await apiClient(config);
    return response.data;
  };
};

/**
 * Create a mutation function with notification handling
 * @param {string} endpoint - The endpoint name
 * @param {string} method - The HTTP method (post, put, patch, delete)
 * @param {string} url - The URL path or URL function that takes parameters
 * @param {Object} options - Additional options
 * @returns {function} A mutation function
 */
export const createMutationFn = (endpoint, method, url, options = {}) => {
  // Set default notification options (all disabled by default)
  const notificationOptions = {
    showProgress: false,
    showSuccessNotification: false,
    showErrorNotification: false,
    ...options
  };

  return async (data) => {
    try {
      // Show progress notification if enabled
      if (notificationOptions.showProgress) {
        notification.progress(notificationOptions.progressMessage);
      }

      // Get the appropriate enhanced API client
      let apiClient;
      switch (endpoint) {
        case 'auth':
          apiClient = enhancedAuthApi;
          break;
        case 'company':
          apiClient = enhancedCompanyApi;
          break;
        case 'leadForm':
          apiClient = enhancedLeadFormApi;
          break;
        case 'location':
          apiClient = enhancedLocationApi;
          break;
        default:
          apiClient = applyDefaultMiddleware(getApiClient(endpoint));
      }

      // Calculate the URL if it's a function
      const resolvedUrl = typeof url === 'function' ? url(data) : url;

      // Make the request
      const response = await apiClient({
        method,
        url: resolvedUrl,
        data,
        ...options
      });

      // Clear progress notification if it was shown
      if (notificationOptions.showProgress) {
        notification.destroy();
      }

      // Show success notification if enabled and available
      if (notificationOptions.showSuccessNotification &&
        response.data &&
        response.data.notificationType &&
        response.data.notification) {
        notification[response.data.notificationType](response.data.notification);
      }

      return response.data;
    } catch (error) {
      // Clear progress notification if it was shown
      if (notificationOptions.showProgress) {
        notification.destroy();
      }

      // Show error notification if enabled
      if (notificationOptions.showErrorNotification) {
        if (error.response?.status >= 500 || error.response?.status === 429) {
          notification.error(i18n.global.t('general.serverError'));
        } else if (notificationOptions.errorMessage) {
          notification.error(notificationOptions.errorMessage);
        } else if (error.response?.data?.message) {
          notification.error(error.response.data.message);
        } else if (error.message) {
          notification.error(error.message);
        }
      }

      throw error;
    }
  };
};

export {
  enhancedAuthApi as authApiVueQuery,
  enhancedCompanyApi as companyApiVueQuery,
  enhancedLeadFormApi as leadFormApiVueQuery,
  enhancedLocationApi as locationApiVueQuery
};

export default {
  createQueryFn,
  createMutationFn,
  authApiVueQuery: enhancedAuthApi,
  companyApiVueQuery: enhancedCompanyApi,
  leadFormApiVueQuery: enhancedLeadFormApi,
  locationApiVueQuery: enhancedLocationApi
};
