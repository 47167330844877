<template>
  <div class="flex flex-col justify-center items-center">
    <div class="sm:w-5/6 md:w-4/5 lg:w-3/4 xl:w-2/3">
      <h1 v-if="mode==='update'" class="text-center text-sm font-light mb-2">{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryUpdateTitle') }}</h1>
      <p class="text-center text-lg font-semibold mb-2">{{ $t('publicArea.registerCompany.steps.termsOfService.title') }}</p>
      <p class="text-left text-xs font-light mt-4 mb-2">{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryTitle') }}:</p>
      <ul class="list-disc list-inside text-left mt-4 space-y-3 mb-8">
        <li>{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP1') }}</li>
        <li class="font-semibold">{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP2') }}</li>
        <li>{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP3') }}</li>
        <li v-html="$t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP4')"/>
      </ul>
      <div class="mt-6 flex justify-center">
        <a :href="conditionsUrl" target="_blank" class="btn btn-secondary px-12 py-2">{{ $t('publicArea.registerCompany.steps.termsOfService.showConditions') }}</a>
      </div>
      <div class="mt-6">
        <input v-model="accept" type="checkbox" :class="['mt-2', 'mr-2', 'mb-3', tries > 0 && accept === false ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
        ><span>{{ $t('publicArea.registerCompany.steps.termsOfService.accept') }}</span>
      </div>
      <div class="flex justify-center mt-5">
        <!-- Use isPending directly in the template instead of a computed property -->
        <button class="btn btn-lg btn-primary" @click="submit">
          {{ isPending ? $t('general.submitting') : $t('general.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useMutation } from '@tanstack/vue-query';
  import { createMutationFn } from '@/api/api.js';
  import notification from '../../../connections/notification';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ useScope: 'global' });

  // Define emits
  const emit = defineEmits(['next-step']);

  // Setup store and reactive state
  const store = useStore();
  const accept = ref(false);
  const tries = ref(0);

  const props = defineProps({
    mode: { type: String, default: 'register' },
  });

  // Computed properties
  const conditionsUrl = computed(() => import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL + '/conditions');
  const company = computed(() => store.state.company.company);

  // Setup mutation
  const { isPending, isError, isSuccess, error, mutate } = useMutation({
    mutationFn: (data) => createMutationFn('company', 'patch', `/companies/${company.value.id}/accept-conditions`)(data),
    onSuccess: (response) => {
      if (response.notificationType === 'success') {
        store.commit('company/setCompany', response.content.company);
        emit('next-step');
      } else {
        notifyValidationError(response.data.message);
      }
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        notifyValidationError(error.response.data.message);
      }
    },
  });

  // Utility functions
  const notifyValidationError = (message) => {
    notification.error(message || 'An error occurred');
  };

  // Submit method
  const submit = () => {
    if (accept.value === false) {
      tries.value++;
      notification.warning(t('publicArea.registerCompany.steps.termsOfService.notifyMissingAccept'));
      return;
    }

    // Use mutation
    mutate();
  };
</script>

<style scoped>
</style>
