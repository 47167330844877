<template>
  <div v-if="companyLead.hasOwnProperty('id') && fields.length > 0" class="p-4 print-view">

    <lead-content :company-lead="companyLead"
                  :fields="fields"
                  :formdata="companyLead.lead"
                  class="mt-4"
                  :print="true"
    />
  </div>
</template>

<script>
  import LeadContent from '../LeadContent.vue';
  import store from '../../../../store/mainStore.js';

  export default {
    name: 'LeadPrintView',
    components: { LeadContent },
    props: {
    },
    data() {
      return {
      };
    },
    computed: {
      leadsFromStore() {
        return store.state.company.leads;
      },
      companyLead() {
        if(this.leadsFromStore.companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
          return this.leadsFromStore.companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid);
        } else if(this.leadsFromStore.searchLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
          return this.leadsFromStore.searchLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid);
        } else {
          return {};
        }
      },
      allFields() {
        return store.state.company.leads.fields
      },
      fields() {
        return this.allFields.filter(obj => obj.form_id === this.companyLead.lead.form.id)
      }
    },
  };
</script>

<style scoped>

</style>
