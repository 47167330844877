<template>
  <div class="relative mt-2 rounded shadow-md p-4 bg-white w-full" >
    <div class="flex justify-between cursor-pointer" @click="toggleShow">
      <div class="font-bold text-base">Zu viele Offerten erhalten?</div>
      <div><circle-button :icon="['fas', isExpanded ? 'caret-up' : 'caret-down']" size="xs" /></div>
    </div>
    <div v-if="localShow">
      <div class="text-base font-semibold mt-3">Helfen Sie mit, betrügerische Firmen zu finden</div>
      <div>Leider kommt es immer wieder vor, dass Firmen bei uns Anfragen beziehen, aber gleichzeitig weitere <strong>Scheinfirmen</strong> betreiben. Sie offerieren z.B. mit zwei Firmen bei Ihnen, einmal etwas teurer, einmal etwas günstiger. So erhoffen sie sich zu mehr Aufträgen zu kommen.</div>
      <div class="mt-2"><strong>Diese Praxis ist bei uns verboten.</strong> Es ist zum Einen für Sie als Kunde mühsam, wenn sich so viele Firmen melden, zum anderen ist es aber auch gegenüber den anderen, ehrlichen Firmen sehr unfair.</div>
      <div class="text-base font-semibold mt-3">So können Sie helfen</div>
      <ul class="list-disc list-inside">
        <li>Haben Sie nur über top-offerten.ch Angebote angefordert?</li>
        <li>Wurden Sie von anderen Firmen kontaktiert, als hier aufgelistet?</li>
        <li>Melden Sie uns die Namen der anderen Firmen</li>
      </ul>
      <textarea v-model="formData.text"
                class="w-full border border-gray-400 mt-2"
      />
      <button class="btn btn-md btn-secondary" @click="store">Abschicken</button>
      <div v-if="status">
        {{ status }}
      </div>
    </div>

  </div>
</template>

<script>
  import CircleButton from '../../snippets/CircleButton.vue';
  import leadFormApi from '../../connections/leadForm.js';
  import store from '../../store/mainStore.js';

  export default {
    name: 'Betrayers',
    components: { CircleButton },
    props: {
      lead: Object,
      isExpanded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localShow: false,
        formData: {
          text: this.lead.betrayers,
        },
        status: ''
      }
    },
    watch: {
      // Synchronisiere den lokalen Zustand mit dem Prop
      isExpanded(newVal) {
        this.localShow = newVal;
      }
    },
    methods: {
      toggleShow() {
        this.localShow = !this.localShow;
        // Event nach oben senden, um dem Parent mitzuteilen, dass sich der Zustand geändert hat
        this.$emit('toggle');
      },
      store() {
        leadFormApi.patch('leads/' + this.lead.id + '/betrayers', this.formData, { 'progress': true }).
        then(response => this.status = 'Vielen Dank für Ihre Mithilfe!');
      }
    },
  };
</script>