import { createQueryFn } from '../api.js';

// ----------------
// QUERY HOOKS for location.top-offerten.ch
// ----------------

/**
 * Get postcodes, cached for 15 minutes
 * @param {Object} country - The country object containing iso2 code (optional, defaults to 'CH')
 * @param {Object} options - Additional query options
 */
export const usePostcodesQuery = (country, options = {}) => {
  const countryCode = country?.iso2 || 'CH';

  return {
    queryKey: ['postcodes', countryCode],
    queryFn: createQueryFn('location', 'get', `/countries/${countryCode}/postcodes`),
    select: (response) => response.data,
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    ...options
  };
};
