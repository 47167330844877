<template>
  <availability-preferences :is-preferred="false" />
</template>

<script>
  import AvailabilityPreferences from './AvailabilityPreferences.vue';

  export default {
    name: 'ClosedDays',
    components: { AvailabilityPreferences }
  };
</script>