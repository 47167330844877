<template>
  <base-form-input
    :id="id"
    v-model="emailAddress"
    :label="defaultLabel"
    :errors="validationErrors"
    :is-valid="isValid"
    :help-text="helpText"
    :container-class="containerClass"
    :input-class="inputClass"
    :placeholder="placeholder"
    :required="required"
    :show-icon="showIcon"
    :loading="isPending"
    type="email"
    @blur="validateEmail"
  />
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import { useI18n } from 'vue-i18n';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import { companyApiVueQuery } from '@/api/api.js';

  const { t } = useI18n({ useScope: 'global' });

  // Model for two-way binding
  const emailModel = defineModel();

  // Props
  const props = defineProps({
    id: { type: String, default: 'email' },
    label: { type: String, default: '' },
    helpText: { type: String, default: '' },
    required: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
    containerClass: { type: String, default: '' },
    inputClass: { type: String, default: '' },
    showIcon: { type: Boolean, default: true },
    errors: { type: Array, default: () => null },
  });

  // Emits
  const emit = defineEmits(['validation-success', 'validation-error', 'email-changed']);

  // Reactive state
  const emailAddress = ref(emailModel.value || '');
  const validationErrors = ref(props.errors);
  const emailLastValue = ref(emailModel.value || '');

  // Computed properties
  const isValid = computed(() => Boolean(emailAddress.value) && !validationErrors.value);
  const defaultLabel = computed(() => props.label || t('general.Email'));

  // Watchers for two-way binding and external changes
  watch(emailAddress, (newValue) => {
    emailModel.value = newValue;
    emit('email-changed', newValue);
  });

  watch(() => emailModel.value, (newValue) => {
    if (newValue !== emailAddress.value) {
      emailAddress.value = newValue;
    }
  });

  watch(() => props.errors, (newErrors) => {
    validationErrors.value = newErrors;
  });

  // Email validation mutation
  const emailMutation = useMutation({
    mutationFn: (email) => companyApiVueQuery.post('register/email/check', { email }, { progress: false }),
    onSuccess: (response) => {
      const isSuccess = response.data.notificationType === 'success';

      validationErrors.value = isSuccess
        ? null
        : [t('publicArea.registerCompany.steps.registerUserWithCompany.warningEmail')];

      if (isSuccess) {
        emailAddress.value = response.data.content.email;
        emit('validation-success', response.data.content.email);
      } else {
        emit('validation-error', validationErrors.value);
      }
    },
    onError: () => {
      validationErrors.value = [t('publicArea.registerCompany.steps.registerUserWithCompany.warningEmail')];
      emit('validation-error', validationErrors.value);
    }
  });

  const { isPending } = emailMutation;

  // Validation function
  function validateEmail() {
    if (emailAddress.value && emailLastValue.value !== emailAddress.value) {
      validationErrors.value = null;
      emailMutation.mutate(emailAddress.value);
      emailLastValue.value = emailAddress.value;
    }
  }
</script>
