import Bugsnag from '@bugsnag/js';
import store from '@components/store/mainStore';
import { authApi } from './apiClient';
import axios from 'axios';

/**
 * Middleware to handle API calls
 * This provides a way to add interceptors to axios instances
 * Each middleware is a function that takes an axios instance and returns it
 */

/**
 * Basic error logging middleware
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const errorLoggingMiddleware = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Only log certain errors to Bugsnag
      if (!error.response ||
        (error.response && [401, 403].includes(error.response.status) &&
          !['/login', '/logout', '/user', '/users/create', '/users/create-with-password'].includes(error.config.url))) {
        Bugsnag.notify(error);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 * Authentication handling middleware
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const authMiddleware = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle authentication issues
      if (error.response && [401, 403].includes(error.response.status) &&
        !['/login', '/logout', '/user', '/users/create', '/users/create-with-password'].includes(error.config.url)) {
        store.dispatch('auth/checkUser');
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 * Validation error handling middleware
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const validationMiddleware = (axiosInstance) => {
  // Request interceptor to clear validation errors
  axiosInstance.interceptors.request.use(
    (config) => {
      store.commit('SET_VALIDATION_ERROR', null, { root: true });
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor to set validation errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 422) {
        store.commit('SET_VALIDATION_ERROR', error.response.data.errors, { root: true });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 * CSRF token handling middleware
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const csrfMiddleware = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;

      // Handle CSRF token mismatch
      if (response && response.status === 419 && !config._retry) {
        config._retry = true;

        try {
          // Get CSRF token and retry the original request
          await authApi.get('/sanctum/csrf-cookie');
          return axios(config);
        } catch (csrfError) {
          return Promise.reject(csrfError);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 * Version tracking middleware
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const versionTrackingMiddleware = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      // Update version info if available
      if (response.data.myVipanyVersion) {
        store.commit('setMyVipanyBackendVersion', response.data.myVipanyVersion, { root: true });
      }
      return response;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

/**
 * Apply multiple middleware to an axios instance
 * @param {import('axios').AxiosInstance} axiosInstance
 * @param {Array<Function>} middlewareList
 * @returns {import('axios').AxiosInstance}
 */
export const applyMiddleware = (axiosInstance, middlewareList = []) => {
  return middlewareList.reduce((client, middleware) => middleware(client), axiosInstance);
};

/**
 * Apply all default middleware to an axios instance
 * @param {import('axios').AxiosInstance} axiosInstance
 * @returns {import('axios').AxiosInstance}
 */
export const applyDefaultMiddleware = (axiosInstance) => {
  return applyMiddleware(axiosInstance, [
    errorLoggingMiddleware,
    authMiddleware,
    csrfMiddleware,
    versionTrackingMiddleware
  ]);
};

export default {
  errorLoggingMiddleware,
  authMiddleware,
  validationMiddleware,
  csrfMiddleware,
  versionTrackingMiddleware,
  applyMiddleware,
  applyDefaultMiddleware
};
