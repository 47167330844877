<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <div>
        <div class=" mb-2">
          <status-circle v-if="companyLead.status !== 2"
                         :company-lead="companyLead"
                         area="customer"
          />
        </div>
        <div class="text-base sm:text-xl text-left font-semibold sm:font-extrabold sm:mt-2">{{ companyPublicName(company) }}</div>
        <div class="flex cursor-pointer" @click="goToCompanyReviews">
          <rating v-if="ratingComponent === 'Rating'"
                  :review="company.company_reviews.length > 0 ? company.review_avg : 0"
                  class="text-xs"
          />
          <rating-topofferten v-else
                              :review="company.company_reviews.length > 0 ? company.review_avg : 0"
                              class="w-24"
                              style="height:17px"
          />
          <div v-if="company.company_reviews.length > 0" class="ml-2 text-gray-500">Ø {{ company.review_avg }} / Total {{ company.company_reviews.length }}</div>
        </div>

        <div class="text-md sm:text-sm mt-2">
          {{ location.address }}, {{ location.postcode }} {{ location.city }}<br>{{ $t('customerArea.quoteOverview.company.phone') }}:
          {{ location.phone ?? company.phone }}
        </div>
      </div>
    </div>
    <div class="flex flex-wrap mt-3">
      <a :href="'tel:' + company.phone" class="sm:hidden">
        <round-button :icon="['fal','phone-volume']" :label="$t('customerArea.quoteOverview.company.phone')" @click="" />
      </a>
      <a v-if="companyLead.website.url" :href="companyLead.website.url" target="_blank">
        <round-button :icon="['fal','globe']" :label="$t('customerArea.quoteOverview.company.website')" />
      </a>
      <a :href="'mailto:' + location.email?? company.email">
        <round-button :icon="['fal','at']" :label="$t('customerArea.quoteOverview.company.email')" />
      </a>
      <a v-if="company.uid" :href="'https://www.uid.admin.ch/Detail.aspx?uid_id=' + company.uid" target="_blank">
        <round-button :icon="['fal','check-circle']" :label="$t('customerArea.quoteOverview.company.register')" />
      </a>
      <round-button v-if="desktop"
                    :icon="['fal','gem']"
                    class="hidden sm:flex"
                    :active="show.companyReviews"
                    :label="$t('customerArea.quoteOverview.company.reviews')"
                    data-cy="icon-menu-reviews"
                    @click="goToCompanyReviews"
      />
      <round-button v-if="3 === 2" :icon="['fal','calendar']" :label="$t('customerArea.quoteOverview.company.appointements')" />
      <round-button v-if="desktop"
                    :icon="['fal','comments']"
                    class="hidden sm:flex"
                    :active="show.chat"
                    :label="$t('customerArea.quoteOverview.company.chat')"
                    data-cy="icon-menu-chat"
                    @click="show.chat = ! show.chat; show.companyReviews = null"
      />
    </div>

    <!--    <customer-buttons v-if="!desktop" :company-lead="companyLead"></customer-buttons>-->

    <div v-if="show.companyReviews" class="mt-3">
      <div class="font-semibold">
        {{ $t('customerArea.quoteOverview.company.reviewsWithCount', { length: company.company_reviews.length }) }}
      </div>
      <reviews :company-lead="companyLead" :company="company" @close-reviews="show.companyReviews = false" />
    </div>

    <div v-if="show.chat" class="mt-3">
      <chat :company-lead="companyLead" :scroll="false" />
    </div>
  </div>
</template>

<script>
  import { companyMixin } from '../../../plugins/mixin';
  import RoundButton from '../../snippets/RoundButton.vue';
  import CircleButton from '../../snippets/CircleButton.vue';
  import StatusCircle from '../../snippets/StatusCircle.vue';
  import Reviews from '../CompanyFocus/Reviews.vue';
  import Chat from '../../sharedComponents/Chat.vue';
  import store from '../../store/mainStore';
  import CustomerButtons from './CustomerButtons.vue';
  import RatingTopofferten from '../../snippets/RatingTopofferten.vue';
  import Rating from '../../snippets/Rating.vue';

  export default {
    name: 'Company',
    components: { Rating, RatingTopofferten, CustomerButtons, Chat, Reviews, StatusCircle, CircleButton, RoundButton },
    mixins: [companyMixin],
    props: {
      companyLead: Object,
      location: Object,
      desktop: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        company: this.companyLead.company,
        show: {
          companyReviews: false,
          chat: false,
          confirm: false,
        },
        ratingComponent: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany' ? 'Rating' : 'RatingTopofferten',
      };
    },
    computed: {},
    created() {
    },
    methods: {
      confirmJob() {
        store.dispatch('customer/confirmedJob', [this.companyLead.id]);
      },
      goToCompanyReviews() {
        if (this.desktop) {
          this.show.companyReviews = !this.show.companyReviews;
          this.show.chat = null;
        } else {
          this.$router.push({ name: 'CustomerReviews', params: { lead_uuid: this.companyLead.lead_uuid, customer_company_id: this.companyLead.company_id } }).catch(err => {
          });
        }
      },
    },
  };
</script>

<style scoped>

</style>
