import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend Day.js with plugins
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Zurich');

export const dayjsHelper = {
  dayjs,

  showDate(date) {
    return dayjs(date).format('DD.MM.YYYY');
  },

  showDateTime(date) {
    return dayjs(date).format('DD.MM.YYYY, HH:mm');
  },

  showDateText(date) {
    return dayjs(date).format('DD. MMM YY');
  },

  countDaysSinceDate(date) {
    return dayjs().diff(dayjs(date), 'days');
  },

  timeFromNow(date) {
    return dayjs(date).fromNow();
  },

  isCreatedInCurrentMonth(date) {
    return dayjs(date).isSame(dayjs(), 'month');
  },

  isInMoreThanDays(date, days) {
    return dayjs(date).subtract(days, 'days').isAfter(dayjs());
  },

  isInLessThanDays(date, days) {
    return dayjs(date).subtract(days, 'days').isBefore(dayjs());
  },

  isOlderThan(date, minutes) {
    return dayjs(date).isBefore(dayjs().subtract(minutes, 'minutes'));
  },

  isInPast(date) {
    return dayjs(date).isBefore(dayjs());
  },

  isInFuture(date) {
    return dayjs(date).isAfter(dayjs());
  },

  addDaysToDate(date, days) {
    return dayjs(date).add(days, 'days');
  },

  isOlderThanInDays(date, days = 0) {
    return dayjs(date).isBefore(dayjs().subtract(days, 'days'));
  },

  isYoungerThan(date, minutes) {
    return dayjs(date).isAfter(dayjs().subtract(minutes, 'minutes'));
  },

  /***
   * Function to change the language and load language files dynamically
   * unfortunately it does not work if used dynamically like await import('dayjs/locale/${locale}');
   * - locale files as defined in the @see i18n.js::LOCALES constant
   *
   * To see the supported locales, check the folder in node_modules/dayjs/locale/
   */
  async changeLanguage(locale) {
    try {
      switch (locale) {
        case "en-US":
        case undefined:
          // English is dayjs library default
          break;

        case "de-ch":
          await import('dayjs/locale/de-ch');
          break;

        case "fr-ch":
          await import('dayjs/locale/fr-ch');
          break;

        case "it-ch":
          await import("dayjs/locale/it-ch");
          break;

        case "pt-PT":
          await import("dayjs/locale/pt");
          break;

        case "tr-TR":
          await import("dayjs/locale/tr");
          break;

        case "sr-RS":
          await import("dayjs/locale/sr");
          break;

        case "sq-AL":
          await import("dayjs/locale/sq");
          break;
      }

      dayjs.locale(locale);
    } catch (error) {
      console.error(`Failed to load locale file for ${locale}:`, error);
    }
  },
};

// Export Day.js instance for more flexibility
export default dayjsHelper;
