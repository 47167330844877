import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n'
import { dayjsHelper } from './dayjsHelper';

/***
 This File is nearly the same as the i18n.js in the landingpages project, the only difference is the import path of
 the json files: const messages = await import(`./lang/${locale}.json` )
 Unfortunately, it's not possible to make this generic and use the same file for both projects.
 Reason: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations

 When the locale is changed, the DataPreparation.vue Component reacts to the change and reloads the data.
 @see DataPreparation.vue::watchLocale()
 ***/

export const LOCALES = {
  'de-CH': { locale: 'de-CH', translation_key: 'locale.german', label: 'Deutsch', short:'DE', checked: false, importance:1 },
  'en-US': { locale: 'en-US', translation_key: 'locale.english', label: 'English (Englisch)', short:'EN', checked: false, importance:2 },
  'fr-CH': { locale: 'fr-CH', translation_key: 'locale.french', label: 'Français (Französisch)', short:'FR', checked: false, importance:3 },
  'it-CH': { locale: 'it-CH', translation_key: 'locale.italian', label: 'Italiano (Italienisch)', short:'IT', checked: false, importance:4 },
  'pt-PT': { locale: 'pt-PT', translation_key: 'locale.portuguese', label: 'Português (Portugiesisch)', short:'PT', checked: false, importance:5 },
  'es-ES': { locale: 'es-ES', translation_key: 'locale.spanish', label: 'Español (Spanisch)', short:'ES', checked: false, importance:6 },
  'tr-TR': { locale: 'tr-TR', translation_key: 'locale.turkish', label: 'Türkçe (Türkisch)', short:'TR', checked: false, importance:7 },
  'sr-RS': { locale: 'sr-RS', translation_key: 'locale.serbian', label: 'Српски (Serbisch)', short:'SR', checked: false, importance:8 },
  'sq-AL': { locale: 'sq-AL', translation_key: 'locale.albanian', label: 'Shqiptare (Albanisch)', short:'SQ', checked: false, importance:9 },
};

let i18n = null;

export function setupI18n(options = { locale: 'none', legacy: true, useScope: 'global' }) {
  i18n = createI18n(options)
  // console.log ('setupI18n with options: ' + JSON.stringify(options) + ' but locale not loaded yet.');

  i18n.ready = false;

  return i18n;
}

export function setI18nLocale(locale) {
  i18n.ready = false;
  console.log('setI18nLocale: ' + locale);

  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }

  document.querySelector('html').setAttribute('lang', locale)

  i18n.ready = true;
}

export async function loadLocaleMessages(locale) {
  i18n.ready = false;
  if (!(locale in LOCALES)) {
    console.error('Unsupported locale: ' + locale);
    locale = LOCALES['de-CH'].locale;
  }

  const messages = await import(`./../lang/${locale}.json` )
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export async function loadAndSetLocale(locale) {
  if (i18n.global.locale === locale) {
    return Promise.resolve();
  }

  await loadLocaleMessages(locale).then(() => {
    setI18nLocale(locale);
  });

  await dayjsHelper.changeLanguage(locale);

  return nextTick();
}

/**
 * As of 05.2024 vue-cal does not support de-ch and fr-ch and other of these locales,
 * so we only use the language without the locale
 * https://antoniandre.github.io/vue-cal/#api
 *
 * To see which locales are supported, check the folder in node_modules/vue-cal/dist/i18n/
 */
export function getCurrentLocaleForVueCal() {
  if (i18n === null) {
    return 'en';
  }

  // As there is no pt in vue-cal but pt-pt, we need to change it
  if (i18n.global.locale === 'pt-PT') {
    return 'pt-pt';
  }

  return (i18n.global.locale).substring(0,2);
}

// Export the i18n instance for use in other files
export { i18n };
