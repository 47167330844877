<template>
  <div>
    <!-- Grid-Layout, das standardmäßig zweispaltig ist -->
    <div class="grid" :class="{ 'grid-cols-2': !expandedItem, 'grid-cols-1': expandedItem }">
      <!-- Erste Spalte - Umzugsboxen -->
      <div :class="{ 'xl:hidden sm:p-2 pr-1': !expandedItem, 'xl:hidden sm:p-2': expandedItem, 'hidden': expandedItem && expandedItem !== 'boxes' }">
        <div class="relative rounded shadow-md p-4 bg-white w-full mt-2">
          <router-link :to="{ name: 'CustomerShop' }">
            <div class="flex justify-between cursor-pointer">
              <div class="font-bold text-sm sm:text-base">Umzugsboxen kaufen</div>
            </div>
          </router-link>
          <div class="my-2 text-red-500 text-sm sm:text-base">Bester Deal</div>
          <div class="my-2 font-semibold text-red-500 text-sm sm:text-base">Gratis Lieferung</div>
        </div>
      </div>

      <!-- Zweite Spalte - Komponenten mit den Togglable-Inhalten -->
      <div :class="{ 'pl-1 sm:p-2': !expandedItem, 'sm:p-2': expandedItem, 'hidden': expandedItem === 'boxes' }">
        <!-- Explanation-Komponente mit erweiterter Funktionalität -->
        <explanation
          :lead="currentLead"
          class="xl:hidden"
          @toggle="toggleItem('explanation')"
          :is-expanded="expandedItem === 'explanation'"
        />

        <!-- Betrayers-Komponente mit erweiterter Funktionalität -->
        <betrayers
          v-if="currentLead.hasOwnProperty('id')"
          :lead="currentLead"
          class="xl:hidden"
          @toggle="toggleItem('betrayers')"
          :is-expanded="expandedItem === 'betrayers'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Explanation from './Explanation.vue';
  import Betrayers from './Betrayers.vue';

  export default {
    name: 'MobileInfoGrid',
    components: {
      Explanation,
      Betrayers
    },
    props: {
      currentLead: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        expandedItem: null // Speichert, welches Element aktuell erweitert ist (null, 'explanation', 'betrayers', oder 'boxes')
      }
    },
    methods: {
      // Methode zum Umschalten des erweiterten Zustands
      toggleItem(itemName) {
        if (this.expandedItem === itemName) {
          // Wenn bereits expandiert, dann zuklappen
          this.expandedItem = null;
        } else {
          // Ansonsten auf das neue Element umschalten
          this.expandedItem = itemName;
        }
      }
    }
  }
</script>