<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.achievements.experienceReport.title') }}</p>
    </template>

    <div class="panel">
      <div class="text-lg font-semibold">{{ $t('companyArea.achievements.experienceReport.title') }}</div>
      <div class="mt-2 mb-4">{{ $t('companyArea.achievements.experienceReport.explanation') }}</div>

      <div class="text-base font-semibold mt-4">{{ $t('companyArea.achievements.experienceReport.benefits') }}</div>
      <ul class="my-2 list-disc pl-5">
        <li v-html="$t('companyArea.achievements.experienceReport.benefit1')"></li>
        <li>{{ $t('companyArea.achievements.experienceReport.benefit2') }}</li>
        <li>{{ $t('companyArea.achievements.experienceReport.benefit3') }}</li>
      </ul>

      <div class="text-base font-semibold mt-4">{{ $t('companyArea.achievements.experienceReport.examples') }}</div>
      <div>{{ $t('companyArea.achievements.experienceReport.examplesText') }}</div>
      <div class="flex mt-1">
        <a href="https://top-offerten.ch/fensterreinigung/sg/wil-sg" class="px-2 py-1 border rounded-md mr-3 mb-3 bg-green-300 hover:bg-gray-300 cursor-pointer text-sm" target="_blank">Umzugsreinigung
          Wil</a>
        <a href="https://top-offerten.ch/umzugsfirma/zh/maur" class="px-2 py-1 border rounded-md mr-3 mb-3 bg-green-300 hover:bg-gray-300 cursor-pointer text-sm" target="_blank">Umzug Maur</a>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-5">
        <a href="#" name="create"></a>
        <a href="#" name="submit"></a>
        <a href="#create" class="py-5 border text-center text-lg rounded-md bg-gray-100 hover:bg-gray-300 cursor-pointer font-semibold" @click="show.content = 'create'"
        >{{ $t('companyArea.achievements.experienceReport.createReport') }}</a>
        <a href="#submit" class="py-5 border text-center text-lg rounded-md bg-gray-100 hover:bg-gray-300 cursor-pointer font-semibold" @click="show.content = 'submit'">
          {{ $t('companyArea.achievements.experienceReport.submitReport') }}
        </a>
      </div>

      <div v-if="show.content === 'create'">
        <div class="font-semibold mt-4 text-base">{{ }}</div>
        <ul class="my-2 list-disc pl-5">
          <li>{{ $t('companyArea.achievements.experienceReport.guide1') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.guide2') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.guide3') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.guide4') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.guide5') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.guide6') }}</li>
        </ul>

        <div class="font-semibold mt-4 text-red-600 text-base">{{ $t('companyArea.achievements.experienceReport.iPhoneTitle') }}</div>
        <div>{{ $t('companyArea.achievements.experienceReport.iPhoneText') }}</div>
        <div class="font-semibold mt-1">{{ $t('companyArea.achievements.experienceReport.iPhoneSettings') }}</div>

        <div class="text-base font-semibold mt-5">{{ $t('companyArea.achievements.experienceReport.questionsTitle') }}</div>
        <div>{{ $t('companyArea.achievements.experienceReport.questionsText') }}</div>
        <button class="btn btn-sm btn-primary mt-3" @click="show.searchLead = true">{{ $t('companyArea.achievements.experienceReport.selectCustomerFirst') }}</button>

        <div v-if="selectedLead.hasOwnProperty('id')" class="text-sm font-semibold mt-4 text-green-600">{{ $t('companyArea.achievements.experienceReport.questionsForCustomer') }}
          {{ selectedLead.lead.name }} {{ selectedLead.lead.surname }}, {{ selectedLead.lead.city }}
        </div>

        <ul v-if="selectedLead.hasOwnProperty('id')" class="my-2 list-disc pl-5">
          <li>{{ $t('companyArea.achievements.experienceReport.question1') }}</li>
          <li v-if="textSnippets('moving')[0] && textSnippets()[2] !== textSnippets('moving')[3]">
            {{ $t('companyArea.achievements.experienceReport.question2', { from: textSnippets()[2], to: textSnippets('moving')[3] }) }}
          </li>
          <li v-if="textSnippets('moving')[0] && textSnippets()[2] === textSnippets('moving')[3]">
            {{ $t('companyArea.achievements.experienceReport.question3', { from: textSnippets()[2] }) }}
          </li>
          <li class="font-semibold">{{ $t('companyArea.achievements.experienceReport.question4', { company: textSnippets('moving')[1] }) }}</li>
          <li class="font-semibold">{{ $t('companyArea.achievements.experienceReport.question5', { takenBy: selectedLead.lead.taken_by, companyName: companyName }) }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.question6') }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.question7', { companyName: companyName }) }}</li>
          <li v-if="textSnippets('moving')[0]">{{ $t('companyArea.achievements.experienceReport.question8', { companyName: companyName }) }}</li>
          <li v-if="textSnippets('moving')[0]">{{ $t('companyArea.achievements.experienceReport.question9', { companyName: companyName }) }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.question10', { company: textSnippets('moving')[1] }) }}</li>
          <li>{{ $t('companyArea.achievements.experienceReport.question11', { companyName: companyName }) }}</li>
        </ul>
      </div>

      <div v-else-if="show.content === 'submit'">
        <div class="text-sm font-semibold mt-4">{{ $t('companyArea.achievements.experienceReport.submitReport') }}</div>
        <div class="bg-gray-100 border rounded-md p-4 my-3">
          <div class="font-semibold mb-2">{{ $t('companyArea.achievements.experienceReport.step1') }}</div>
          <button class="btn btn-sm btn-primary mt-3" @click="show.searchLead = true">{{ $t('companyArea.achievements.experienceReport.selectCustomerFirst') }}</button>
          <div v-if="selectedLead.hasOwnProperty('id')" class="font-semibold mt-3 text-green-600">{{ selectedLead.lead.name }} {{ selectedLead.lead.surname }}, {{ selectedLead.lead.city }}</div>
        </div>
        <div v-if="selectedLead.hasOwnProperty('id')" class="bg-gray-100 border rounded-md p-4 my-3">
          <div class="font-semibold mb-2">{{ $t('companyArea.achievements.experienceReport.step2') }}</div>
          <div class="grid grid-cols-2 gap-1">
            <div>{{ $t('companyArea.achievements.experienceReport.durance') }}</div>
            <div><input v-model="formdata.duration" type="text" class="rounded shadow w-full border-gray-300"></div>
            <div>{{ $t('companyArea.achievements.experienceReport.workers') }}</div>
            <div><input v-model="formdata.workers" type="text" class="rounded shadow w-full border-gray-300"></div>
            <div>{{ $t('companyArea.achievements.experienceReport.special') }}</div>
            <div><textarea v-model="formdata.text" class="rounded shadow w-full border-gray-300"/></div>
          </div>
          <button class="btn btn-sm btn-primary mt-3" @click="storeText">{{ $t('general.submit') }}</button>
        </div>

        <div v-if="blogPost.hasOwnProperty('id')" class="bg-gray-100 border rounded-md p-4 my-3">
          <div class="font-semibold mb-2">{{ $t('companyArea.achievements.experienceReport.step3') }}</div>
          <media-library-collection name="images"
                                    :validation-rules="{ accept: ['image/png', 'image/jpeg'], maxSizeInKB: 5000 }"
                                    :upload-domain="landingpageUrl"
                                    :initial-value="images"
                                    :route-prefix="'api/blog-posts/' + blogPost.id + '/media/images'"
                                    @is-ready-to-submit-change="isReadyToSubmit = $event"
                                    @change="onChangeImages"
          />
          <button v-if="collection === 'images'"
                  :disabled="!isReadyToSubmit"
                  class="btn btn-primary btn-sm mt-4"
                  @click="submitForm"
          >
            {{ $t('general.save') }}
          </button>
        </div>
        <div v-if="blogPost.hasOwnProperty('id')" class="bg-gray-100 border rounded-md p-4 my-3">
          <div class="font-semibold mb-2">{{ $t('companyArea.achievements.experienceReport.step4') }}</div>
          <media-library-collection name="videos"
                                    :validation-rules="{ accept: ['video/*'], maxSizeInKB: 1000000 }"
                                    :upload-domain="landingpageUrl"
                                    :route-prefix="'api/blog-posts/' + blogPost.id + '/media/videos'"
                                    @is-ready-to-submit-change="isReadyToSubmit = $event"
                                    @change="onChangeVideo"
          />
        </div>
      </div>

      <confirmation-modal v-if="show.searchLead" @close="show.searchLead = false">
        <div>
          <div class="grid grid-cols-2 gap-1">
            <div>
              <input v-model="nameFilter"
                     type="text"
                     class="border rounded"
                     placeholder="Name filtern"
              >
            </div>
            <div>
              <input v-model="cityFilter"
                     type="text"
                     class="border rounded"
                     placeholder="Ort filtern"
              >
            </div>
          </div>
          <div v-for="companyLead in filteredLeads" class="grid grid-cols-2 gap-1 hover:bg-blue-100 cursor-pointer mt-3" @click="selectLead(companyLead)">
            <div>{{ companyLead.lead.name }} {{ companyLead.lead.surname }}</div>
            <div>{{ companyLead.lead.city }}</div>
          </div>
        </div>
      </confirmation-modal>
    </div>
  </layout>
</template>

<script>
  import Layout from '../../Layout.vue';
  import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue3-attachment';
  import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection';
  import companyApi from '../../connections/company';
  import store from '../../store/mainStore';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import landingpageApi from '../../connections/landingpage';

  export default {
    components: {
      ConfirmationModal,
      Layout,
      MediaLibraryAttachment,
      MediaLibraryCollection,
    },
    data() {
      return {
        landingpageUrl: import.meta.env.VITE_VUE_APP_LANDINGPAGE_ADMIN_URL,
        logo: [],
        images: [],
        isReadyToSubmit: false,
        media: [],
        collection: '',
        selectedLead: {},
        blogPost: {},
        leaddata: {
          period: 'all',
          search: '',
        },
        formdata: {
          duration: '',
          workers: '',
          text: '',
        },
        show: {
          searchLead: false,
          content: '',
        },
        nameFilter: '',
        cityFilter: '',
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      companyName() {
        return this.company.label ?? this.company.name;
      },
      companyProfile() {
        return store.state.company.companyProfile;
      },
      leads() {
        return store.state.company.leads;
      },
      filteredLeads() {
        let leads = this.leads.companyLeads;
        if (this.nameFilter.length > 0) {
          leads = leads.filter(item => item.lead.name.toLowerCase().includes(this.nameFilter.toLowerCase()) || item.lead.surname.toLowerCase().includes(this.nameFilter.toLowerCase()));
        }
        if (this.cityFilter.length > 0) {
          leads = leads.filter(item => item.lead.city.toLowerCase().includes(this.cityFilter.toLowerCase()));
        }
        return leads;
      },
    },
    created() {
      companyApi.get('/companies/' + this.company.id + '/company-profile/media', { notification: false }).then(response => [store.commit('company/setCompanyProfile', response.data.content.companyProfile), this.logo = response.data.content.logo, this.images = response.data.content.images]).catch();
    },
    methods: {
      onChangeVideo(media) {
        this.collection = 'video';
        this.media = media;
      },
      onChangeImages(media) {
        this.collection = 'images';
        this.media = media;
        console.log(this.media);
      },
      selectLead(companyLead) {
        this.selectedLead = companyLead;
        this.show.searchLead = false;
        if (this.show.content === 'submit') {
          landingpageApi.get('blog-posts/companies/' + this.company.id + '/leads/' + companyLead.lead_id + '/find', { notification: false }).then(response => this.setBlogPost(response)).catch();
        }
      },
      setBlogPost(response) {
        if (response.data.notificationType === 'success' && response.data.content['blogPost']) {
          this.blogPost = response.data.content['blogPost'];
          if (this.blogPost.company_info) {
            this.formdata.text = this.blogPost.company_info['text'];
            this.formdata.duration = this.blogPost.company_info['duration'];
            this.formdata.workers = this.blogPost.company_info['workers'];
          }
          if (response.data.content['images']) {
            this.images = response.data.content['images'];
          }
        }
      },
      storeText() {
        this.formdata.company_uuid = this.company.uuid;
        landingpageApi.post('blog-posts/companies/' + this.company.id + '/leads/' + this.selectedLead.lead_id + '/store', this.formdata, { notification: true })
          .then(response => {
            console.log('Response from API:', response.data); // Log die komplette Antwort
            if (response.data.content && response.data.content.id) {
              this.blogPost = response.data.content;
            } else {
              console.error('BlogPost ID fehlt in der API-Antwort');
            }
          })
          .catch(error => console.error('Fehler beim Speichern des Textes:', error));      },
      submitForm() {
        landingpageApi.patch('blog-posts/' + this.blogPost.id + '/media/' + this.collection + '/update', { payload: this.media }, { notification: false }).then(response => this.collection = '').catch();
      },
      searchLead() {
        store.dispatch('company/searchLeads', this.leaddata);
      },
      textSnippets(work) {
        let type = false;
        let company = 'Umzugsfirma';
        let location = this.selectedLead.lead.city;
        let moveTo = 'XY';
        if (work === 'moving' && [1, 2, 30, 31].includes(this.selectedLead.lead.form_id)) {
          type = true;
          moveTo = JSON.parse(this.selectedLead.lead.dynamic_form)['133'] ?? 'XY';
        } else if (work === 'cleaning' && [3, 20, 21, 33, 34].includes(this.selectedLead.lead.form_id)) {
          type = true;
          company = 'Reinigungsfirma';
        }
        return [type, company, location, moveTo];
      },
    },
  };

</script>

<style scoped>
    .media-library-thumb-image {
        padding: 10px;

    }
</style>
