<!-- BaseFormInput.vue -->
<template>
  <div class="relative" :class="containerClass">
    <label :for="id" class="block text-gray-700">
      {{ label }}
    </label>

    <input
      :id="id"
      v-model="model"
      :type="type"
      :required="required"
      :placeholder="placeholder"
      :class="[ 'w-full mt-1', errors ? 'border-red-500 border-2' : 'border-gray-400 border', inputClass ]"
      @blur="onBlur"
    />

    <label-state-icon v-if="props.showIcon" :required="true" :label-state="labelState" :loading="loading" />

    <!-- Error Message -->
    <p v-if="errors" class="text-red-500">
      {{ errors[0] }}
    </p>

    <!-- Optional Help Text -->
    <p v-if="helpText" class="text-gray-500 text-xs mb-2">
      {{ helpText }}
    </p>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import LabelStateIcon from '@/components/inputs/ui/LabelStateIcon.vue';

  const model = defineModel();  // This replaces modelValue prop and update:modelValue emit

  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['blur', 'icon-click']);

  const labelState = computed(() => {
    return Boolean(model?.value) && !props?.errors;
  });

  const onBlur = (event) => {
    emit('blur', event);
  };

  const onIconClick = () => {
    emit('icon-click');
  };
</script>
