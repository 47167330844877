<template>
  <div class="mx-auto px-4 sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <base-form-input
        id="firstname"
        v-model="form.firstname"
        :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.firstName')"
        :errors="errors['firstname']"
        :is-valid="form.firstname?.length > 0"
        required
      />

      <base-form-input
        id="lastname"
        v-model="form.lastname"
        :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.lastName')"
        :errors="errors['lastName']"
        :is-valid="form.lastname?.length > 0"
        required
      />
    </div>

    <base-form-input
      class="mt-4"
      id="companyName"
      v-model="form.companyName"
      :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.officialCompanyName')"
      :errors="errors['companyName']"
      :is-valid="form.companyName?.length > 0"
      :help-text="$t('publicArea.registerCompany.steps.registerUserWithCompany.officialCompanyNameInfo')"
      required
    />

    <base-form-input
      class="mt-4"
      id="companyLabel"
      v-model="form.companyLabel"
      :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.companyLabel')"
      :errors="errors['companyLabel']"
      :is-valid="true"
      :help-text="$t('publicArea.registerCompany.steps.registerUserWithCompany.companyLabelInfo')"
      :required="false"
      :show-icon="false"
    />

    <div class="grid grid-cols-1 sm:grid-cols-5 gap-4 mt-4">
      <div class="sm:col-span-5">
        <base-form-input
          :container-class="'space-y-0.5'"
          id="address"
          v-model="form.address"
          :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.street')"
          :errors="errors['address']"
          :is-valid="form.address?.length > 0"
          required
        />
      </div>

      <div class="sm:col-span-5">
        <div class="relative space-y-0.5">
          <zip-city-input v-if="postcodes?.length > 0"
                          v-model:postcode="form.postcode"
                          v-model:postcode_id="form.postcode_id"
                          v-model:country="form.country"
                          v-model:city="form.city"
                          :postcodes="postcodes"
                          :validation="{}"
                          :validation-sign="true"
                          :show-label="true"
          />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      <email-input
        :container-class="'space-y-0.5'"
        id="email"
        v-model="form.email"
        :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.email')"
        :errors="errors['email']"
        required
        @validation-success="handleEmailValidationSuccess"
        @validation-error="handleEmailValidationError"
      />

      <phone-input
        :container-class="'space-y-0.5'"
        id="phone"
        v-model="form.phone"
        :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.phone')"
        :initial-errors="errors['phone']"
        required
        @validation-success="handlePhoneValidationSuccess"
        @validation-error="handlePhoneValidationError"
      />
    </div>

    <website-input
      class="mt-4"
      id="website"
      v-model="form.website"
      :initial-errors="errors['website']"
      :no-website-value="noWebsiteValue"
      :initial-no-website="isNoWebsite"
      required
      @validation-success="handleWebsiteValidationSuccess"
      @validation-error="handleWebsiteValidationError"
      @no-website-changed="handleNoWebsiteChange"
    />

    <base-form-input
      :container-class="'space-y-0.5'"
      id="password"
      v-model="form.password"
      :label="$t('publicArea.registerCompany.steps.registerUserWithCompany.password')"
      :errors="errors['password']"
      :is-valid="form.password?.length > 0"
      type="password"
      required
    />

    <p v-if="errors['uid']" class="text-red-500 my-4 text-lg font-bold">
      {{ errors['uid'][0] }}
    </p>

    <div class="flex justify-center mt-6">
      <button
        class="btn btn-lg btn-primary"
        type="button"
        @click="submitForm"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? $t('general.loading') : $t('general.next') }}
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useMutation, useQuery } from '@tanstack/vue-query';
  import { useStorage } from '@vueuse/core';
  import { useI18n } from 'vue-i18n';
  import store from '../../../store/mainStore';
  import notification from '../../../connections/notification';
  import authApi from '../../../connections/auth';
  import ZipCityInput from '@/components/inputs/zipCityInput.vue';
  import BaseFormInput from '@/components/inputs/BaseFormInput.vue';
  import PhoneInput from '@/components/inputs/PhoneInput.vue';
  import WebsiteInput from '@/components/inputs/WebsiteInput.vue';
  import EmailInput from '@components/inputs/EmailInput.vue';
  import { usePostcodesQuery } from '@/api/queries/location.js';

  const { t } = useI18n({ useScope: 'global' });

  const props = defineProps({
    company: {
      type: Object,
      default: () => ({}),
    },
    uidData: {
      type: Object,
      default: () => ({}),
    },
  });

  const emit = defineEmits(['next-step']);

  const form = useStorage('register-company-form', {
    firstname: '',
    lastname: '',
    companyName: '',
    companyLabel: '',
    email: '',
    website: '',
    phone: '',
    address: '',
    postcode_id: '',
    postcode: '',
    city: '',
    country: '',
    password: '',
    uid: '',
    uidErgaenzung: '',
    locale: 'de-CH',
  });

  const isSubmitting = ref(false);
  const errors = ref({});
  const { data: postcodes = [] } = useQuery(usePostcodesQuery())

  watch(() => props.uidData, (newUidData) => {
    if (newUidData && Object.keys(newUidData).length > 0) {
      prefillForm(newUidData);
    }
  }, { immediate: true });

  // Mutations
  const createUserWithCompanyMutation = useMutation({
    mutationFn: (payload) => authApi.post('/users/create-with-company', payload, { notification: false }),
    onMutate: () => { isSubmitting.value = true; },
    onSuccess: handleCreateSuccess,
    onError: handleCreateError,
    onSettled: () => { isSubmitting.value = false; },
  });

  function handlePhoneValidationSuccess(validatedPhone) {
    errors.value['phone'] = null;
    form.value.phone = validatedPhone;
  }

  function handlePhoneValidationError(phoneErrors) {
    errors.value['phone'] = phoneErrors;
  }

  function handleWebsiteValidationSuccess(validatedUrl) {
    errors.value['website'] = null;
    form.value.website = validatedUrl;
  }

  function handleWebsiteValidationError(websiteErrors) {
    errors.value['website'] = websiteErrors;
  }

  function handleEmailValidationSuccess(validatedEmail) {
    errors.value['email'] = null;
    form.value.email = validatedEmail;
  }

  function handleEmailValidationError(emailErrors) {
    errors.value['email'] = emailErrors;
  }

  // Special value to indicate "no website"
  const noWebsiteValue = 'in-progress';

  // Computed to check if the current form website value indicates "no website"
  const isNoWebsite = computed(() => form.value.website === noWebsiteValue);

  // New handler for "no website" checkbox changes
  function handleNoWebsiteChange(noWebsiteChecked) {
    // You can add any additional logic here if needed
    // The WebsiteInput component already handles updating the model value
  }

  // Functions
  function prefillForm(uidData) {
    if (uidData.uid === 'no-uid') {
      form.value.uid = '';
      return;
    }

    form.value = {
      ...form.value,
      firstname: uidData.vorname || '',
      lastname: uidData.nachname || '',
      companyName: uidData.offiziellerName || '',
      companyLabel: uidData.firmenLabel || '',
      email: props.company?.email || '',
      website: props.company?.website || '',
      phone: props.company?.phone || '',
      address: uidData.strasse || '',
      postcode: uidData.plz || '',
      city: uidData.ort || '',
      country: 'CH',
      uid: uidData.uid || '',
      uidErgaenzung: uidData.uidErgaenzung || '',
    };
  }

  function submitForm() {
    errors.value = {};
    createUserWithCompanyMutation.mutate(form.value);
  }

  function handleCreateSuccess(response) {
    const { data } = response;
    const content = data?.content;
    if (!content) return;

    if (content.valid) {
      store.commit('setUser', content.user, { root: true });
      store.commit('company/setInitialCompanyData', content.company);
      store.commit('setCompanies', content.employees);
      store.commit('setSelectedCompany', content.company.id);
      window.dataLayer?.push({ event: 'register_company', email: content.user.email });
      errors.value = {};
      emit('next-step');
    } else {
      notification.warning(t('general.error'));
    }
  }

  function handleCreateError(error) {
    if (error.response?.status === 422) {
      errors.value = error.response.data.errors || {};
      notification.warning(t('publicArea.registerCompany.steps.registerUserWithCompany.correctErrors'));
    } else {
      notification.warning(t('general.error'));
    }
  }
</script>
